/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import Signature from '../../icons/signature.svg'
import ArrowTop from '../../icons/arrow-top-right.svg'
import ChevronRight from '../../icons/chevron-right.svg'
import load from '../../icons/load.svg'
import signatureblack from '../../icons/signature-black.svg'
import checkmark_white from '../../icons/checkmark_white.svg'

import React, { useContext, Dispatch, SetStateAction, useState, useEffect } from 'react'
import { RootObjectCompletedContracts } from 'app/modules/apps/docusign/_models'
import { RootStoreContext } from 'stores/rootStore'
import GrenCheck from '../../icons/checkmark-circle.svg'
import Bubble from '../../icons/speech-bubble-19.svg'
import Clock from '../../icons/clock-alt-1.svg'
import Chevron2 from '../../icons/chevron-right2.svg'
import Clock2 from '../../../dashboard/userDasboardComponents/userDashboardIcons/clock-alt-2.svg'
import Check from '../../../dashboard/userDasboardComponents/userDashboardIcons/checkmark-circle.svg'
import { useQuery } from 'react-query'
import { getContractByUser, getContractByUserEmailAllTable } from 'app/modules/apps/contract/_requests'
import { getAllStaff } from 'app/modules/apps/staff/core/_requests'
import Logo from '../../icons/logo.png'
import NewMessage from 'app/pages/messages/NewMessage'
import { IReply, initialReplyData } from 'app/modules/apps/appointments/core/_models'
import Arrow from '../../icons/arrow-left.svg'
import Graduate from '../../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/GraduateHat.svg'
import UsersIcon from '../../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/Users.svg'
import Plane from '../../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/air-mail.svg'
import Briefcase from '../../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/briefcase-2.svg'
import Houseonly from '../../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/Houseonly.svg'
import UsersIcon2 from '../../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/User2.svg'
import Swal from 'sweetalert2'
import { getAllCompletedContractsByEmail, updateuofrep } from 'app/modules/apps/docusign/_requests'
import { getDocumentsbyContractId } from 'app/modules/apps/userDocument/_requests'
import { PdfView } from 'app/pages/services/services/formsManagerClient.tsx/pdfView'

interface IProps {
  name: string
  contractData: RootObjectCompletedContracts | undefined
  contractStatus: string
  step: number
  setStep: Dispatch<SetStateAction<number>>
  contractId: string
  setContractId: Dispatch<SetStateAction<string>>
  start: Dispatch<SetStateAction<boolean>>
  maxStep: number
  setMaxStep: Dispatch<SetStateAction<number>>
  contract: any
}

const FinalStep: React.FC<IProps> = ({ step, setStep, maxStep, contractId, contract, }) => {
  const [loadinguseofrepdoc, setLoadinguseofrepdoc] = useState(true)
  const [usrfound, setUsrFound] = useState(false)
  const [showUserRepresentative, setShowUserRepresentative] = useState<any>('')
  const [loadinguseofrep, setLoadingUseOfRep] = useState<boolean>(true)
  const [openUseOfRepModal, setOpenUseOfRepModal] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [staffEmail, setStaffEmail] = useState<any>()
  const [active2, setActive2] = useState(false)
  const [photo, setPhoto] = useState('')
  const [staff, setStaff] = useState<any>(null)
  const [showmessage, setShowMessage] = useState(false)
  const [showMessageRows, setShowMessageRows] = useState(true)
  const [replyMessage, setReplyMessage] = useState(false)
  const [composepostdata, setComposepostdata] = useState<IReply>(initialReplyData)
  const [createMessage, setCreateMessage] = useState(false)
  const [list, setlist] = useState(true)
  const [emailStaff, setEmailStaff] = useState('')
  const [useOfRep, setUseOfRep] = useState<any>('Cargando')

  const rootStore = useContext(RootStoreContext)
  const { user } = rootStore.authStore


  const handleConfirmation = async () => {
    setLoadingUseOfRep(true)

    const overlay = document.createElement('div')
    overlay.style.position = 'fixed'
    overlay.style.top = '0'
    overlay.style.left = '0'
    overlay.style.width = '100%'
    overlay.style.height = '100%'
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'
    overlay.style.zIndex = '1'
    document.body.appendChild(overlay)

    const docu = await getAllCompletedContractsByEmail(user?.email!, user?.createdAt?.toString()!, '0000-00-00')
    if (docu) {
      const tieneTypeUseofrep = docu.folderItems.filter((elemento: any) =>
        elemento.type === 'useofrepresentative' &&
        elemento.useofrep_envelopeid === contractId)

      if (tieneTypeUseofrep && tieneTypeUseofrep.length > 0) {
        setUseOfRep(tieneTypeUseofrep[0].envelopeId)
        setUsrFound(true)
        await updateuofrep(contractId)
        setLoadinguseofrepdoc(false)
        setLoadingUseOfRep(false)
        setOpenUseOfRepModal(false)

        Swal.fire({
          icon: 'success',
          html:
            `<p style="color: #13C296">Firma confirmada con éxito</p>
            <p>Ha firmado con éxito la autorización de uso de documentos.</p>`,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'CONTINUAR',
          customClass: {
            confirmButton: 'btn-no-shadow',
          },
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          document.body.removeChild(overlay)
        })
      } else {
        setLoadingUseOfRep(false)
        document.body.removeChild(overlay)
      }
    }
  }
  const handleClose = () => {
    setReplyMessage(false)
    setlist(true)
  }

  const { data: dataFromDatabase, status: contractByUserStatus } = useQuery(
    'allcontracsDb',
    async () => await getContractByUser(user?.id!)
  ) /* contratos */
  const { data: StaffData, status: Staffstatus } = useQuery(
    'getStaff',
    async () => await getAllStaff()
  )
  const visitProducts = [
    'bb4056fb-2440-4a06-aec1-8d13349e7427', 'a10980f2-57f3-43c7-b863-a995915389de', '3f056a93-1f4b-4598-9aec-f929ecd00e97',
    'fd5b2b7d-8772-4ee6-91cc-53f710351021', '7664135d-5b88-4640-8bf1-6f56d7f2a78a', 'daa261eb-6263-4b6e-acbb-4343bd4ce35a',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9', 'b0e0313b-6344-4733-90a0-6d6031f8be1e', '41669177-9782-4052-bb8d-08b6c29c928f'
  ]

  const studyProducts = [
    '94726637-76e7-438b-9494-99b136bbc7ea', 'bb4056fb-2440-4a06-aec1-8d13349e7427', 'b9ada976-a4fe-444d-9f6a-dd58c0928120',
    '8327593a-cc82-4703-afaa-72030f67c001', 'f9b3dcd2-b420-4cc0-b5df-575f9a5be911', '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800',
    '32549570-adf0-4add-ad43-5c49fed6566d', '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c'
  ]

  const workProducts = [
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61', '41b1a433-5900-4b96-85f3-7e2c8fce0a41', '50eb5404-9ae2-4655-91ae-89d2e8a6fa73',
    '19ca7bf2-6514-478b-bb78-c7861619fe39', 'e377ed33-a53a-4312-8842-1a689237b07c', '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b',
    'e8e477d1-f126-4a64-93da-539b3715730d', '30a323ab-236c-461c-93ea-28308c8fafa6', '08e1143b-4f7f-48db-a289-9a3fc40f8037',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa', 'a645705f-f9f7-47c6-9319-5a1026da84d2', '6e420420-d96c-4cb8-a145-95a7964ed618',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095', '9015fb11-d513-4f6e-9de9-aab92d5d2b42', '4f050e6f-e9eb-4ee0-b12c-465d2d715c16',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c', '0dac7746-02c0-47db-be28-14755d62a64d', 'c263052c-80cf-4fc3-a628-88e51621f4e3',
    '6420335e-30aa-46c5-892b-e1fa23914733', '52096218-da31-4e01-a406-0e06a9ca4d07', 'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac',
    '229d7b91-7a9b-407a-988b-9e45096bff5b', 'c4bb7c15-0e15-487f-b161-21d0426d82f9', 'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994',
    'c6df5f52-670d-4d7f-809e-72cb59fa684a', '8a29f12d-a898-4d52-8fd1-f184094cd8c3', '65245c09-8c4d-485a-abaa-8f752d4dfe39',
    'c8d8dce1-7959-4271-92c9-ddd6bbda35d4', '588bed15-e8fb-4216-8580-6de93f1512ae', '1d2b991b-7d75-4127-99d8-19f884efcfd0',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f', 'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f', 'b6f813c5-0911-4b86-be17-0e74bc332316'
  ]

  const residenceProducts = [
    'c7571404-31f4-45b3-898a-160d4e72afc5', '9665d0d1-c145-4dc6-be9f-d4c69006604c', '6f317d28-cab2-4316-9a25-3f62e4e047e6',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d', '3320f244-f338-43d0-8006-503003d16501', 'b58daf73-88be-41ee-8a0d-9e485748ced8',
    '7ac7912e-9514-4281-924b-1a36a4dd3065', '9c1f03d1-5100-4a50-8030-cfb8ebad4480', '48b466b6-d8e1-48f8-ae32-2877760f92b7',
    'd1195212-1cdc-4792-b88b-824922f63c36', '2a7387ab-c1dc-45f1-a6b3-3f22c0fc30e5', 'f455901e-a97e-4c2d-abd4-8a359778e88a',
    '068de1e8-3074-4259-8a2a-1418d8f39ca3', '87bcc196-0f68-421b-9591-4e3565d69783', '728d916e-47fe-48ae-9628-468712651938',
    '0c9f691d-2b23-4c7d-8419-7f55785418f6', 'fb1a576c-f5a1-4d72-9aa8-d2f8eb5fa34d', '1de57333-82cf-4aaa-8a9b-23fadab35a3f',
    '7b84fba5-c4a9-48bc-ac44-f84a97db6ea2', 'bdda48a1-ea4d-480d-9e2c-e14e7904dd27', '054e28db-5fa8-487b-a452-38b94392f1ce',
    'cd90d231-265a-4be0-9982-54cf3aee6537', '346454f5-f81f-4a37-abc6-8e6d17f26512', '29c268c2-b5a9-4888-82c9-a209b5e20962',
    'a9b2deee-cb6f-4aa0-a521-8ecc02d32394', '7d8df96c-dd71-48aa-b0f7-301e59706212', '03030060-aa44-452a-ad54-80fedf29a547',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d', '5848c9c4-bfc9-49b0-881d-9cab020dd1e5', 'ee8c9b93-151c-40ce-86ca-8f8f2aa3a644',
    '1ec2dc58-5776-49d3-b487-f373ffd1e0a3', '4762b784-650d-429b-b48d-02c351f34085'
  ]

  const specialProducts = [
    '8dcb5115-bdce-45e3-8b50-d2d592020713',
    'f9fe4a37-7284-4bf7-a22e-f0f12678bb99'
  ]

  const renderIcons = (applicants: any[]) => {
    let showPlane = false
    let showGraduate = false
    let showBriefcase = false
    let showHouse = false
    let showUsersIcon2 = false

    applicants.forEach(applicant => {
      if (visitProducts.includes(applicant.productId)) showPlane = true
      if (studyProducts.includes(applicant.productId)) showGraduate = true
      if (workProducts.includes(applicant.productId)) showBriefcase = true
      if (residenceProducts.includes(applicant.productId)) showHouse = true
      if (specialProducts.includes(applicant.productId)) showUsersIcon2 = true
    })
  }
  if (contractByUserStatus === 'success' && Staffstatus === 'success' && active2 === false) {
    dataFromDatabase.data.map((usercontract: any) => {
      if (usercontract.contracts_users_envelope_id === contractId) {
        StaffData.map((staff: any) => {
          if (staff.id === usercontract.contracts_users_staffId) {
            if (staff.user.photo !== null) {
              setPhoto(`${staff.user.photo.path}/${staff.user.photo.id}-avatar-large.jpg`)
            }
            setStaff(staff)
          }
        })
      }
    })
    setActive2(true)
  }

  useEffect(() => {
    if (staff) {
      setEmailStaff(staff.user.email)
      composepostdata.email = staff.user.email
      composepostdata.userId = staff.user.id

    }
  }, [composepostdata, staff])

  const { data: contractsByUserEmail,
    refetch: refetchcontractsByUserEmail,
    status: statuscontractsByUserEmail } = useQuery(
      'allcontrDasdb',
      async () => await getContractByUserEmailAllTable(user?.id!), {
      enabled: (user?.id !== undefined),
      cacheTime: 0
    })


  const {
    data: documentByContract,
    status: userDocStatus,
    refetch: docRefetch,
  } = useQuery('getDocumentByContract', async () => await getDocumentsbyContractId(contractId), {
    enabled: Boolean(contractId !== undefined),
    cacheTime: 0
  })


  useEffect(() => {
    const fetchData = async () => {
      if (status === 'success') {
        setLoading(false)
      }
      if (statuscontractsByUserEmail === 'success') {
        if (contractsByUserEmail &&
          contractsByUserEmail.data &&
          contractsByUserEmail.data.length > 0) {
          const tieneTypeUseofrep = contractsByUserEmail.data.filter((elemento: any) =>
            elemento.envelope_id === contractId &&
            elemento.signeduofrep === true)
          const contractFound = contractsByUserEmail.data.find((elemento: any) =>
            elemento.hasOwnProperty('envelope_id') &&
            elemento.envelope_id === contractId)
          if (tieneTypeUseofrep && tieneTypeUseofrep.length > 0) {
            setUsrFound(true)
            await updateuofrep(contractId)
            setLoadingUseOfRep(false)
          } else
            if (tieneTypeUseofrep && tieneTypeUseofrep.length === 0) {
              setLoadingUseOfRep(false)
            }
          if (contractFound && contractFound.staffId) {
            setStaffEmail(contractFound.staffId.user.email)
          }
          const docu = await getAllCompletedContractsByEmail(user?.email!, user?.createdAt?.toString()!, '0000-00-00')
          const tiene = docu.folderItems.filter((elemento: any) =>
            elemento.type === 'useofrepresentative' &&
            elemento.useofrep_envelopeid === contractId)

          if (tiene && tiene.length > 0) {
            setUseOfRep(tiene[0].envelopeId)
            setLoadinguseofrepdoc(false)
          }
        }
      }
    }
    fetchData()

  }, [ contractByUserStatus, userDocStatus, statuscontractsByUserEmail])
  return (
    <><button
      className='button-ui button-secondary button-s'
      onClick={() => { setStep(1) }}>
      <img src={Arrow} alt="Arrow" />
      <p >VOLVER A SOLICITUDES</p>
    </button><div style={{ width: '100%', height: '1px', backgroundColor: '#DFE4EA', marginTop: '1rem', marginBottom: '1rem' }}></div><div className='main-data-continer'>

        <div className='text-container '>
          <h1 className='step-header-title mt-4'>Contrato n° {contractId}</h1>
          {contract.map((item: any) =>
            <>
              {item.envelope_id === contractId ? (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginTop: '20px', alignItems: 'center' }}>
                  <p style={{ fontSize: '12px' }}>Contratado el {item.completedDate.substring(0, 10)}</p>
                  {(() => {
                    // Verificar si 'contracts_users_applicants' está definido
                    const applicants = item.applicants ? JSON.parse(item.applicants) : []
                    let showPlane = false
                    let showGraduate = false
                    let showBriefcase = false
                    let showHouse = false
                    let showUsersIcon2 = false
                    applicants.forEach((applicant: { productId: string }) => {
                      if (visitProducts.includes(applicant.productId)) showPlane = true
                      if (studyProducts.includes(applicant.productId)) showGraduate = true
                      if (workProducts.includes(applicant.productId)) showBriefcase = true
                      if (residenceProducts.includes(applicant.productId)) showHouse = true
                      if (specialProducts.includes(applicant.productId)) showUsersIcon2 = true
                    })
                    // Calcular los tipos de miembro únicos
                    const uniqueMemberTypesCount = applicants.reduce((acc: Record<string, boolean>, applicant: { memberType: string | number }) => {
                      acc[applicant.memberType] = true
                      return acc
                    }, {})
                    const uniqueMemberTypes = Object.keys(uniqueMemberTypesCount).length
                    return (
                      <div style={{ display: 'flex', gap: '10px', marginTop: '-14px' }}>
                        {showPlane && (
                          <button
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '3px 5px',
                              backgroundColor: '#01A9DB',
                              borderRadius: '3px',
                              gap: '5px'
                            }}>
                            <img src={Plane} alt="Plane Icon" />
                          </button>)}

                        {showGraduate && (
                          <button
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '3px 5px',
                              backgroundColor: '#EF5DA8',
                              borderRadius: '3px',
                              gap: '5px'
                            }}
                          >
                            <img src={Graduate} alt="Graduate Icon" />
                          </button>
                        )}

                        {showBriefcase && (
                          <button
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '3px 5px',
                              backgroundColor: '#22AD5C',
                              borderRadius: '3px',
                              gap: '5px'
                            }}>
                            <img src={Briefcase} alt="Briefcase Icon" />
                          </button>)}

                        {showHouse && (
                          <button
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '3px 5px',
                              borderRadius: '3px',
                              gap: '5px',
                              width: '35px'
                            }}
                          >
                            <img src={Houseonly} alt="House Icon" />
                          </button>
                        )}

                        {showUsersIcon2 && (
                          <button
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '3px 5px',
                              backgroundColor: '#DFE4EA',
                              borderRadius: '3px',
                              gap: '5px'
                            }}
                          >
                            <img src={UsersIcon2} alt="Users Icon 2" />
                          </button>
                        )}

                        {/* Icono con el número de tipos de miembros únicos */}
                        <button
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '3px 5px',
                            border: '1px solid #DFE4EA',
                            borderRadius: '3px',
                            gap: '5px'
                          }}
                        >
                          <img src={UsersIcon} alt="User Icon" />
                          <p style={{ fontSize: '10px', fontWeight: 'bold', margin: '0' }}>{uniqueMemberTypes}</p>
                        </button>
                      </div>
                    )
                  })()}
                </div>
              ) : null}
            </>
          )}
          <div className='onboard-guide-container'>
            {/* timeline */}
            <div className='step-line-cont'>
              {step > 1 && (
                <div
                  className='guide-number-done one-btn'
                  onClick={() => {
                    setStep(1)
                  }}
                >
                  <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>1</b></p>
                  <div className='step-number-checked data-final-step-check'>
                    <img style={{ width: '20px', height: '20px' }} src={Check} alt="Check" />
                  </div>
                  <p className='hide-tex step-text' style={{ color: '#13C296' }}>Servicio contratado</p>
                  <div className='greenpath2' style={{ top: '47px', left: '-6px' }} />
                  <img className='data-final-step-chevron' style={{ width: '20px', height: '20px' }} src={Chevron2} alt="Chevron2" />
                </div>
              )}

              {(maxStep > 2 && step > 2) || (maxStep > 2 && step < 2) ? (
                <div
                  className='guide-number-done two-btn'
                  onClick={() => {
                    setStep(2)
                  }}
                >
                  <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>2</b></p>
                  <div className='step-number-checked data-final-step-check'>
                    <img style={{ width: '20px', height: '20px' }} src={Check} alt="Check" />
                  </div>
                  <p className='hide-tex step-text' style={{ color: '#13C296' }}> Completar formularios</p>
                  <div className='greenpath2' style={{ top: '47px', left: '-6px' }} />
                  <img className='data-final-step-chevron' style={{ width: '20px', height: '20px' }} src={Chevron2} alt="Chevron2" />
                </div>
              ) : maxStep >= 2 && step === 2 && (
                <div className='guide-number two-btn'>
                  <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>2</b></p>
                  <p className='hide-tex step-text' style={{ color: 'black' }}> Completar formularios</p>
                  <div className='blackpath' style={{ top: '47px', left: '-6px' }} />
                  <img className='data-final-step-chevron' style={{ width: '20px', height: '20px' }} src={Chevron2} alt="Chevron2" />
                </div>
              )}

              {(maxStep > 3 && step > 3) || (maxStep >= 3 && step < 3) ? (
                <div
                  className='guide-number-done three-btn'
                  onClick={() => {
                    setStep(3)
                  }}
                >
                  <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>3</b></p>
                  <div className='step-number-checked data-final-step-check'>
                    <img style={{ width: '20px', height: '20px' }} src={Check} alt="Check" />
                  </div>
                  <p className='hide-tex step-text' style={{ color: '#13C296' }}> Adjuntar documentos</p>
                  <div className='greenpath2' style={{ top: '47px', left: '-6px' }} />
                  <img className='data-final-step-chevron' style={{ width: '20px', height: '20px' }} src={Chevron2} alt="Chevron2" />
                </div>
              ) : maxStep >= 3 && step === 3 ? (
                <div className='guide-number three-btn'>
                  <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>3</b></p>
                  <p className='hide-tex step-text' style={{ color: 'black' }}> Adjuntar documentos</p>
                  <div className='blackpath' style={{ top: '47px', left: '-6px' }} />
                  <img className='data-final-step-chevron' style={{ width: '20px', height: '20px' }} src={Chevron2} alt="Chevron2" />
                </div>
              ) : (
                <div className='guide-number-deactivate three-btn'>
                  <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>3</b></p>
                  <p className='hide-tex step-text' style={{ color: '#d1d5db' }}> Adjuntar documentos</p>
                  <div className='graypath' style={{ top: '47px', left: '-6px' }} />
                  <img className='data-final-step-chevron' style={{ width: '20px', height: '20px' }} src={Chevron2} alt="Chevron2" />
                </div>
              )}
              {(maxStep >= 5 && step > 4) || (maxStep >= 5 && step < 4) && (
                <div
                  className='guide-number-done four-btn'
                  onClick={() => {
                    setStep(4)
                  }}>
                  <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>4</b></p>
                  <p className='hide-tex step-text' style={{ color: '#13C296' }}>Asignación y segimiento</p>
                  <div className='greenpath2' />
                  <img className='data-final-step-chevron'
                    style={{ width: '20px', height: '20px' }}
                    src={Chevron2} alt="Chevron2" />
                </div>)}
              {step === 4 ? (
                <div className='guide-number four-btn'>
                  <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>4</b></p>
                  <p className='hide-tex step-text' style={{ color: 'black' }}>Asignación y segimiento</p>
                  <div className='blackpath' style={{ top: '66px', left: '-8px', height: '4.5rem' }} />
                  <img className='data-final-step-chevron'
                    style={{ width: '20px', height: '20px' }}
                    src={Chevron2} alt="Chevron2" />
                </div>
              ) : null}
              {(maxStep < 5) && (
                <div className='guide-number-deactivate four-btn'>
                  <p className='white-text' style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>4</b></p>
                  <p className='hide-tex step-text' style={{ color: '#d1d5db' }}>Asignación y segimiento</p>
                  <div className='graypath' />
                  <img className='data-final-step-chevron'
                    style={{ width: '20px', height: '20px' }}
                    src={Chevron2} alt="Chevron2" />
                </div>)}

              {maxStep >= 5 && step < 5 && (
                <div
                  className='guide-number-done five-btn'
                  onClick={() => {
                    setStep(5)
                  }}
                >
                  <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>5</b></p>
                  <p className='hide-tex step-text' style={{ color: '#13C296' }}>Confirmar cartas</p>
                </div>)}
              {step === 5 && (
                <div className='guide-number five-btn'>
                  <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>5</b></p>
                  <p className='hide-tex step-text' style={{ color: 'black' }}>Confirmar cartas</p>
                </div>)}
              {maxStep < 5 &&
                <div className='guide-number-deactivate five-btn'>
                  <p className='white-text' style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>5</b></p>
                  <p className='hide-tex step-text' style={{ color: '#d1d5db' }}>Confirmar cartas</p>
                </div>}
            </div>

            <div className='cooking-container'
              style={{ background: 'white', padding: '2rem', borderRadius: '10px' }}>
              <h1 style={{ fontSize: '30px' }}>Solicitud enviada</h1>
              <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>¡Felicidades {user?.firstName}! Ya estás en el proceso final.</h2>
              <p style={{ fontSize: '18px' }}>
                Su información y documentación ha sido transmitida con éxito.
                En las próximas 48 horas hábiles, su expediente será asignado al equipo legal para revisión.
              </p>
              <div className='data-final-step'>
                {/*  <ul className='data-final-step-ul'>
                  <li className='flex mr-16 gap-2'>
                    <div className='uploader-documents-icon-success'>
                      <img
                        style={{ width: '60px', height: '60px' }}
                        src={GrenCheck}
                        alt='GrenCheck' />
                    </div>
                    <p className='uploader-documents-title'>Asignación del consultor</p>
                  </li>
                  <li className='flex mr-16 gap-2'>
                    <div className='uploader-documents-icon-pending'>
                      <img
                        style={{ width: '60px', height: '60px' }}
                        src={Clock}
                        alt='Clock' />
                    </div>
                    <p className='uploader-documents-title'>Revisión de expediente</p>
                  </li>
                  <li className='flex mr-16 gap-2'>
                    <div className='uploader-documents-icon-deactivated'>
                    </div>
                    <p className='uploader-documents-title'>Preparación de solicitud</p>
                  </li>
                  <li className='flex mr-16 gap-2'>
                    <div className='uploader-documents-icon-deactivated'>
                    </div>
                    <p className='uploader-documents-title'>Envió de aplicación</p>
                  </li>
                </ul> */}
                <div className='consultant-card-container'>
                  {active2 === true && staff !== null ? (
                    <div className='consultant-card-container-inner'>
                      <div className='flex flex-row justify-between w-full' style={{ borderBottom: '1px solid gray', padding: '1rem' }}>
                        <div className='flex flex-row gap-2 '>
                          <img
                            className='consultant-card-img'
                            src={staff.user.photo !== null ? photo : Logo}
                            alt='staff' />
                          <div className='flex flex-col'>
                            <div className='flex'>
                              <p className='consultant-card-name'>{staff.user.firstName}</p> &#160;
                              <p className='consultant-card-name'>{staff.user.lastName}</p>
                            </div>
                            <p className='hide-tex' style={{ fontSize: '14px' }}>Case worker especialista</p>
                          </div>
                        </div>
                       {/*  <button
                          className='close-video-btn contact-btn-step-four'
                          style={{ width: '8rem', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '10px' }}
                          onClick={() => {
                            {
                              setShowMessage(true)
                            }
                          }}>
                          <img src={Bubble} alt="Bubble" />
                          <p className='hide-tex' style={{ fontSize: '16px', margin: '0' }}>Contactar</p>
                        </button> */}
                      </div>
                      {/* <div style={{ padding: '1rem' }}>
                        <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Certificaciones</h2>
                        <p style={{ fontSize: '18px' }}>{staff.description}
                        </p>
                      </div> */}
                    </div>
                  ) : (
                    <div className='consultant-card-container-inner'>
                      <div className='flex flex-row justify-between w-full' style={{ borderBottom: '1px solid gray', padding: '1rem' }}>
                        <div className='flex flex-row gap-2 '>
                          <img className='consultant-card-img' src={Logo} alt='staff' />
                          <div className='flex flex-col'>
                            <p style={{ fontSize: '18px', fontWeight: 'bold' }}>En breve se le asignará un case worker. </p>
                          </div>
                        </div>
                      </div>
                      <div style={{ padding: '1rem' }}>
                        <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>Immiland lo tiene todo</h2>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {showUserRepresentative !== "" && <>
        <div className='user-profile-popup'>
          <div className='user-profile-popup-continer'>
            <button
              style={{
                marginBottom: '10px',
              }}
              className='button-ui button-secondary '
              onClick={() => {
                setShowUserRepresentative("")
              }}>
              <p className='meet-text2' style={{ fontWeight: 'bold', margin: '0' }}>Volver</p>
            </button>
            <>
              <PdfView envelopeId={useOfRep} /></>
          </div>
        </div>
      </>}
              <div className='product-name use-for-representative'>
                        <div className='representation-container'>
                          <div>
                            <div className='flex flex-row gap-2 items-center'>
                              <img style={{ width: '20px', height: '20px' }} src={Signature} alt="Signature" />
                              <h2>Firma de autorización de uso</h2>
                            </div>
                            <p>
                              Ingrese y firme la autorización de uso formal de sus documentos para su proceso de Visa.
                            </p>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', gap: '3rem', marginBottom: '2rem' }}>
                            <div className='step-badge-active2'>
                              {usrfound === false ?
                                <p className='step-badge-active-p-ones'>Por adjuntar</p>
                                :
                                <p  className='step-badge-active-p-ones'>Firmado</p>
                              }
                            </div>
                            <div>
                              {loadinguseofrepdoc === true ?
                                <img style={{ width: '30px' }} src="/media/gif/Rolling-1s-36px.gif" alt="Congratulations GIF" />
                                :
                                <img
                                  className='show-btn2'
                                  src={ChevronRight}
                                  onClick={() => { setShowUserRepresentative(true) }}
                                  alt='ChevronRight'
                                  style={{ cursor: 'pointer' }}
                                />
                              }
                            </div> </div>
                        </div>

                        {loadinguseofrep === false &&
                          usrfound === false &&
                          <div style={{ display: 'flex', gap: '16px', flexDirection: 'row-reverse' }}>
                            <div
                              className='button-form-manual-asign'
                              style={{ width: '11rem' }}
                              onClick={() => { loadinguseofrep === false ? setOpenUseOfRepModal(true) : null }}>
                              <p style={{
                                color: 'white',
                                fontSize: '0.8rem',
                                margin: '0',
                                fontWeight: 'bold'
                              }}>Firmar autorizacion</p >
                              <img style={{ width: '16px', height: '16px' }} src={ArrowTop} alt="ArrowTop" />
                            </div>
                          </div>
                        }
                        {loadinguseofrep === true && <><button style={{ boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)', border: '1px solid white', backgroundColor: '#2CD673', display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center', width: '17rem' }}
                          className='button4'>
                          <b className='meet-text3'>CONFIRMANDO </b>
                          <img style={{ width: '25px', height: '25px', color: 'white' }} src={load} alt="ArrowTop" />
                        </button> </>}

                      </div>

                      {openUseOfRepModal === true && <>
                          <div className='user-profile-popup'>
                            <div className='user-profile-popup-continer'>
                              <div >
                                <div style={{ display: 'flex', gap: '5px' }}>
                                  <img style={{ width: '50px', height: '50px' }} src={signatureblack} alt="ArrowTop" />
                                  <p style={{ fontSize: '30px' }}><b>Firma de autorización de uso</b></p>
                                </div>
                                <p><b>Para continuar, debe firmar la autorización, con los siguientes pasos:</b></p>
                                <div className='container-input-shopping docusign-input'
                                  style={{
                                    backgroundColor: '#fffbeb',
                                    borderRadius: '10px',
                                    width: 'fit-content',
                                    padding: '12px 24px'
                                  }}>
                                  <p>1. Llene sus datos <b>en los campos destacados en amarillo en el documento</b> </p>
                                  <p>2. Click en  <b>FINISH</b> dentro del documento (*)</p>
                                  <p>3. Finalmente en <b>Confirmar firma</b></p>
                                  <p><b>(*) IMPORTANTE: No activar botones del documento aparte de los sugerido aqui</b></p>
                                </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                {usrfound === false && loadinguseofrep === false &&
                                  <button onClick={() => {
                                    /*  setaActiveFetch(true) */
                                    refetchcontractsByUserEmail()
                                    handleConfirmation()
                                  }} style={{
                                    boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
                                    border: '1px solid white',
                                    backgroundColor: '#2CD673',
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '17rem'
                                  }}
                                    className='button4'>
                                    <b className='meet-text3'>CONFIRMAR FIRMA </b>
                                    <img style={{ width: '25px', height: '25px', color: 'white' }}
                                      src={checkmark_white} alt="ArrowTop" />
                                  </button>
                                }
                                {loadinguseofrep === true &&
                                  <button style={{
                                    boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)',
                                    border: '1px solid white',
                                    backgroundColor: '#2CD673',
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '17rem'
                                  }}
                                    className='button4'>
                                    <b className='meet-text3'>CONFIRMANDO </b>
                                    <img style={{ width: '25px', height: '25px', color: 'white' }}
                                      src={load} alt="ArrowTop" />
                                  </button>
                                }
                              </div>
                              <iframe width={'100%s'}
                                height={'600px'}
                                src={`https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=1c6f5d4e-1c02-4be5-9b30-058d5aa74b8d&env=na3&acct=3de08097-1f68-46b3-9980-cb5f80a07e4c&v=2&Client_UserName=${user?.firstName}&Client_Email=${user?.email}&Client_useofrep_envelopeid=${contractId}`}>
                              </iframe>
                            </div>
                          </div>
                        </>}

            </div>
          </div>
        </div>
        {showmessage ? (
          <div className='user-profile-popup'>
            <div className='user-profile-popup-continer'>
              <NewMessage
                contractId={contractId}
                emailStaff={emailStaff}
                setShowMessage={setShowMessage}
                setShowMessageRows={setShowMessageRows}
                setReplyMessage={setReplyMessage}
                composepostdata={composepostdata}
                showSelectFiles={false}
                showShareFiles={false}
                initialContent={composepostdata.content}
                showTemplateSearch={false}
                initialSubject={composepostdata.subject}
                type={'new_message'}
                cc={[]}
                handleClose={handleClose}
                showButtonClose={true}
                category=''
                setCreateMessage={setCreateMessage}
                setComposeData={setComposepostdata} />
            </div>
          </div>
        ) : null}

      </div>




      </>
  )
}
export default FinalStep
