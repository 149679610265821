/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState, Dispatch, SetStateAction, useEffect } from 'react'
import { RootObjectCompletedContracts } from 'app/modules/apps/docusign/_models'
import { RootStoreContext } from 'stores/rootStore'
import Arrow from '../../icons/arrow-left.svg'
import Lifering from '../../icons/lifering.svg'
import World from '../../icons/world.svg'
import Car from '../../icons/cart-alt.svg'
import Pencil from '../../icons/signature.svg'
import Upload from '../../icons/upload-alt.svg'
import Delete from '../../icons/round-box-cross.svg'
import Clock from '../../icons/clock-alt-1.svg'
import Cross from '../../icons/cross-circle.svg'
import ClockY from '../../icons/clock-alt-yellow.svg'
import Eye from '../../icons/eye-alt.svg'
import Chevron2 from '../../icons/chevron-right2.svg'
import checkmark_white from '../../icons/checkmark_white.svg'
import Check from '../../../dashboard/userDasboardComponents/userDashboardIcons/checkmark-circle.svg'
import ChevronRight from '../../icons/chevron-right.svg'
import TumbsUp from '../../icons/thumbs-up.svg'
import { useQuery } from 'react-query'
import { getDocumentsbyContractId } from 'app/modules/apps/userDocument/_requests'
import { getDocumentsByProducts } from 'app/modules/apps/immigration/requirements/core/_requests'
import { uploadFileIntake } from 'app/modules/apps/file/core/_requests'
import Swal from 'sweetalert2'
import { downloadFileLibrary } from 'app/modules/apps/library/core/_requests'
import DocumentView from 'app/pages/services/services/supportDocumentsClient/DocumentView'
import PhotoView from 'app/pages/services/services/supportDocumentsClient/PhotoView'
import { getContractByUser, getContractByUserEmailAllTable } from 'app/modules/apps/contract/_requests'
import { updateContracts } from 'app/modules/apps/docusign/_requests'
import {
  sendEmailForAssignedCaseWorkerForReview,
  sendEmailForCaseWorkerReview,
  sendEmailForUserReview,
} from 'app/modules/apps/users/core/_requests'
import SlideOverComponent from 'app/pages/Cart/UserCart/TranlationHover'
import { CSSTransition } from 'react-transition-group'
import { PdfView } from 'app/pages/services/services/formsManagerClient.tsx/pdfView'
import Graduate from '../../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/GraduateHat.svg'
import UsersIcon from '../../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/Users.svg'
import Plane from '../../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/air-mail.svg'
import Briefcase from '../../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/briefcase-2.svg'
import Houseonly from '../../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/Houseonly.svg'
import UsersIcon2 from '../../../../pages/admin/users/UserMenusoptions/NewIntakeView/Icons/User2.svg'
import CompletedContractsModal from '../../contratacionAnswers/CompletedContractsModal'
import { ContractsTemps } from 'app/modules/apps/contract/_models'
import Box from '../../icons/box-4.svg'
import { findTempsByContractId } from 'app/modules/apps/appointments/core/_requests'

interface IProps {
  name: string
  contractData: RootObjectCompletedContracts | undefined
  contractStatus: string
  step: number
  setStep: Dispatch<SetStateAction<number>>
  contractId: string
  setContractId: Dispatch<SetStateAction<string>>
  start: Dispatch<SetStateAction<boolean>>
  maxStep: number
  setMaxStep: Dispatch<SetStateAction<number>>
  stepBar: number
  setStepBar: Dispatch<SetStateAction<number>>
  listProducts: string[]
  setListProducts: Dispatch<SetStateAction<string[]>>
  contract: any
}

const UploaderDocuments: React.FC<IProps> = ({
  step,
  setStep,
  contractId,
  maxStep,
  setMaxStep,
  setStepBar,
  listProducts,
  contract,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { user, updatingContracts } = rootStore.authStore
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [displayPopup3, setDisplayPopup3] = useState(false)
  const [files, setFiles] = useState<any>(null)
  const [documentId, setDocumentId] = useState<string>('')
  const [productId, setProductId] = useState<string>('')
  const [documentSelected, setDocumentSelected] = useState<string>('')
  const [documentDisclaimer, setDocumentDisclaimer] = useState<string>('')
  const [active3, setActive3] = useState<boolean>(false)
  const [active4, setActive4] = useState(0)
  const [active5, setActive5] = useState(0)
  const [active2, setActive2] = useState(false)
  const [sendingMAil, setSendMAil] = useState(false)
  const [matchedUserData, setMatchedUserData] = useState<any[]>([])
  const [documentsByMember, setDocumentsByMember] = useState<any[]>([])
  const [lookDocument, setLookDocument] = useState<any>()
  const [lookImage, setLookImage] = useState<any>()
  const [progressbar, setProgressbar] = useState(0)
  const [porcentageBase, setPorcentageBase] = useState(0)
  const [completedLenght, setCompletedLenght] = useState(0)
  const [barUpdate, setbarUpdate] = useState(0)
  const [pendingDocuments, setPendingDocuments] = useState<boolean>(false)
  const [reviewingDocuments, setReviewingDocuments] = useState<boolean>(false)
  const [aprovedDocuments, setAprovedDocuments] = useState<boolean>(false)
  const [rejectedDocuments, setRejectedDocuments] = useState<boolean>(false)
  const [allDocuments, setAllDocuments] = useState<boolean>(true)
  const [numDraft, setNumDraft] = useState(-1)
  const [numAproved, setNumAproved] = useState(-1)
  const [numReview, setNumReview] = useState(-1)
  const [numPending, setNumPending] = useState(-1)
  const [showSlideOver, setShowSlideOver] = useState(false)
  const [tranlationHover, setTranlationHover] = useState(false)
  const [useOfRep, setUseOfRep] = useState<any>('Cargando')
  const [listProductFound, setListProductFound] = useState<any>()
  const [staffEmail, setStaffEmail] = useState<any>()
  const { contracts } = rootStore.authStore
  const [activeNextStep, setActiveNextStep] = useState<boolean>(false)
  const [fondoGris, setFondoGris] = useState(true)
  const [popUpSumited, setPopUpSumited] = useState(false)
  const [activeNotificationForMail, setActiveNotificationForMail] = useState(false)
  const [showUserRepresentative, setShowUserRepresentative] = useState<any>('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [dataSelected, setDataSelected] = useState<ContractsTemps>()
  const [contractsFound, setContractsFound] = useState<RootObjectCompletedContracts>({
    resultSetSize: '',
    startPosition: '',
    endPosition: '',
    totalSetSize: '',
    nextUri: '',
    previousUri: '',
    envelopes: [],
    folderItems: [],
  })

  useEffect(() => {
    const fetchTemps = async () => {
      if (contractId) {
        try {
          const tempsData = await findTempsByContractId(contractId)
          console.log('Temps data for contractId:', tempsData)
          if (tempsData && tempsData.length > 0) {
            setDataSelected(tempsData[0])
          }
        } catch (error) {
          console.error('Error fetching temps:', error)
        }
      }
    }

    fetchTemps()
  }, [contractId])
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleTranslationClick = () => {
    setShowSlideOver(true)
    setTranlationHover(true)
  }

  const handleCloseSlideOver = () => {
    setShowSlideOver(false)
  }

  const handleToggleContent = (index: number) => {
    toggleContent(index)
  }

  const [buttonStates, setButtonStates] = useState<number | null>(null)

  const {
    data: documentByContract,
    status: userDocStatus,
    refetch: docRefetch,
  } = useQuery('getDocumentByContract', async () => await getDocumentsbyContractId(contractId), {
    enabled: Boolean(contractId !== undefined),
    cacheTime: 0
  })

  const { data, status, refetch } = useQuery(
    'documents',
    async () => {
      if (listProductFound && listProductFound.length > 0) {
        return await getDocumentsByProducts(listProductFound)
      }
    },
    {
      enabled: Boolean(listProductFound && listProductFound.length > 0),
      cacheTime: 0
    })

  const { data: dataFromDatabase, status: contractByUserStatus } = useQuery(
    'allcontrDb',
    async () => await getContractByUser(user?.id!), {
    enabled: (user?.id !== undefined),
    cacheTime: 0
  })

  const GetMainId = (contractId: string) => {
    dataFromDatabase.data.map((cont: any) => {
      if (contractId === cont.contracts_users_envelope_id) {
        setSteps(cont.contracts_users_id)
      } else {
        setStatus(cont.contracts_users_id)
      }
    })
  }

  function groupProductsByMember(products: any) {
    const groupedProducts = products.reduce((groupedProducts: any, product: any) => {
      const { memberName, memberType, productId, productName } = product
      const key = `${memberName}-${memberType}`
      if (!groupedProducts[key]) {
        groupedProducts[key] = {
          memberName,
          memberType,
          products: [],
        }
      }
      groupedProducts[key].products.push({
        productId,
        productName,
      })
      return groupedProducts
    }, {})
    const result = Object.values(groupedProducts)
    return result
  }

  const setSteps = async (mainId: string) => {
    let dataContractData: object
    if (mainId != null) {
      dataContractData = {
        step: 4,
        status: 1,
      }
    } else {
      dataContractData = {
        step: 4,
        status: 1,
      }
    }
    const result = await updateContracts(mainId, dataContractData)
    if (result) {
      updatingContracts()
    }
  }

  const setStatus = async (mainId: string) => {
    let dataContractData: object
    if (mainId != null) {
      dataContractData = {
        status: 0,
      }
    } else {
      dataContractData = {
        status: 0,
      }
    }
    const result = await updateContracts(mainId, dataContractData)
    if (result) {
      updatingContracts()
    }
  }

  useEffect(() => { }, [])
  useEffect(() => {
    if (contracts && contracts.length > 0) {
      const contractFound = contracts.find((item: any) => item.envelope_id === contractId)
      setListProductFound(JSON.parse(JSON.stringify(contractFound.list_products)))
    }
    if (listProductFound && listProductFound.length > 0) {
      refetch()
      docRefetch()
      refetch()
      docRefetch()
      setActive4(0)
    }
  }, [contracts])

  useEffect(() => {
    if (listProductFound && listProductFound.length > 0) {
      refetch()
      docRefetch()
      refetch()
      docRefetch()
      setActive4(0)
    }
  }, [listProductFound])

  const toggleContent = (index: number) => {
    setButtonStates(prevIndex => (prevIndex === index ? null : index))
  }

  if (status === 'success' &&
    userDocStatus === 'success' &&
    data &&
    dataFromDatabase) {
    if (active4 === 0) {
      const updatedUserData: any = [...data]
      const documentsPerMember: any = []
      dataFromDatabase && dataFromDatabase.data.forEach((cont: any) => {
        if (contractId === cont.contracts_users_envelope_id) {
          const memberInfo: any = groupProductsByMember(JSON.parse(cont.contracts_users_applicants))
          const arrayFoundValidation: any = []
          for (const eachUpdatedUserData of updatedUserData) {
            for (const eachDocument of eachUpdatedUserData) {
              for (const member of memberInfo) {
                const check = arrayFoundValidation.find((eachArrayFoundValidation: any) => {
                  if (eachArrayFoundValidation.memberName === member.memberName &&
                    eachArrayFoundValidation.memberType === member.memberType &&
                    eachDocument.id === eachArrayFoundValidation.documentId &&
                    eachDocument.product.id === eachArrayFoundValidation.productId
                  ) {
                    return true
                  }
                  return false
                })
                if (check !== true &&
                  (member.products.some((producto: any) =>
                    producto.productId === eachDocument.product.id)) === true) {
                  arrayFoundValidation.push({
                    memberName: member.memberName,
                    memberType: member.memberType,
                    documentId: eachDocument.id,
                    productId: eachDocument.product.id
                  })
                  documentsPerMember.push({
                    memberName: member.memberName,
                    memberType: member.memberType,
                    documents: eachDocument,
                    productName: eachDocument.product.name
                  })
                }
              }
            }
          }
        }
      })
      const result: any = []
      documentsPerMember.forEach((elemento: any) => {
        // Busca si ya existe un grupo con las mismas propiedades
        const grupoExistente = result.find((grupo: any) =>
          grupo.memberName === elemento.memberName &&
          grupo.memberType === elemento.memberType &&
          grupo.productName === elemento.productName
        )
        // Si ya existe un grupo con las mismas propiedades, agrega el documento al grupo
        if (grupoExistente) {
          // Verifica si el documento ya está presente en el grupo
          const documentoActual = elemento.documents
          if (!grupoExistente.documents.some((doc: any) => doc.id === documentoActual.id)) {
            grupoExistente.documents.push(documentoActual)
          }
        } else {
          // Si no existe un grupo con las mismas propiedades, crea un nuevo grupo
          result.push({
            memberName: elemento.memberName,
            memberType: elemento.memberType,
            productName: elemento.productName,
            documents: [elemento.documents]
          })
        }
      })
      //END
      const alreadyFound: any = []
      result.map((eachMember: any, index: any) => {
        for (const eachDocument of documentByContract) {
          let found = false
          if (
            eachDocument.memberName === eachMember.memberName &&
            eachDocument.memberType === eachMember.memberType
          ) {
            found = true
          } else {
            found = false
          }
          if (found) {
            const isAlreadyIncluded = alreadyFound.some(
              (doc: any) =>
                doc.document.id === eachDocument.document.id &&
                doc.product.id === eachDocument.product.id &&
                doc.memberName === eachDocument.memberName &&
                doc.memberType === eachDocument.memberType
            )
            if (!isAlreadyIncluded) {
              result[index].documents.push(eachDocument)
              alreadyFound.push(eachDocument)
            }
          }
        }
      })
      const filteredArray = result.map((item: any) => {
        const files = new Set()
        const toRemove = new Set()
        item.documents.forEach((doc: any) => {
          const key = doc.document.id + '|' + doc.product.id
          if (files.has(key)) {
            toRemove.add(key)
          } else {
            files.add(key)
          }
        })
        item.documents = item.documents.filter((doc: any) => {
          const key = doc.document.id + '|' + doc.product.id
          return !toRemove.has(key) || ('file' in doc)
        })
        return item
      })
      let totalRejectedForms = 0
      let totalAprovedForms = 0
      let totalReviewForms = 0
      let totalPendingForms = 0
      filteredArray.map((filt: any) => {
        filt.documents.map((docs: any) => {
          if (docs.status === 'Rechazado') {
            totalRejectedForms++
          }
          if (docs.status === 'Aprovado') {
            totalAprovedForms++
          }
          if (docs.status === 'Pendiente por revisión') {
            totalReviewForms++
          }
          if (!docs.status || docs.status === 'Pendiente por consignar') {
            totalPendingForms++
          }
        })
      })
      setTimeout(() => {
        setNumDraft(totalRejectedForms)
        setNumAproved(totalAprovedForms)
        setNumReview(totalReviewForms)
        const count = filteredArray.reduce((total: any, item: any) => {
          const documentsWithDeletedAt = item.documents.filter((document: any) =>
            !document.deletedAt &&
            !document.file)
          return total + documentsWithDeletedAt.length
        }, 0)
        setNumPending(count)
      }, 2000)
      setDocumentsByMember(filteredArray)
      setActive4(active4 + 1)
    }
  }

  const handleUpload = async (
    documentId: string,
    productId: string,
    memberName: string,
    memberType: string) => {
    const result = await uploadFileIntake(
      files,
      () => { },
      'userdocuments',
      documentId,
      user?.id!,
      contractId,
      productId,
      memberName,
      memberType
    )

    if (result) {
      updatingContracts()
      setPopUpSumited(true)
      setTimeout(() => {
        setSendMAil(true)
        refetch()
        docRefetch()
        setActive4(0)
        setPopUpSumited(false)
      }, 3000)
    }
    getPorcentage()
    refetch()
    docRefetch()
    setDocumentId('')
    setProductId('')
    setTimeout(() => {
      refetch()
      docRefetch()
      setActive2(false)
      setFiles(null)
      setActive4(0)
    }, 500)
  }

  const SendMail = async (email: string) => {
    setActiveNotificationForMail(true)
    if (!staffEmail) {
      setActiveNextStep(true)
      await sendEmailForCaseWorkerReview(email)//43
      await sendEmailForUserReview(email)//42
    } else if (staffEmail) {
      setActiveNextStep(true)
      await sendEmailForAssignedCaseWorkerForReview(staffEmail, 'intake@immilandcanada.com')//54
      await sendEmailForAssignedCaseWorkerForReview(email, 'intake@immilandcanada.com')//54
      await sendEmailForUserReview(email)//172 por asignar
    }
  }

  const convertDocument = async (id: string) => {
    const file = await downloadFileLibrary(id, () => { })
    setLookDocument(file)
  }

  const convertImage = async (document: any) => {
    const file = await downloadFileLibrary(document, () => { })
    const fileReader = new FileReader()
    fileReader.onload = () => {
      const arrayBuffer = fileReader.result
      if (arrayBuffer instanceof ArrayBuffer) {
        const byteArray = new Uint8Array(arrayBuffer)
        const blob = new Blob([byteArray], { type: 'image/png' })
        const imageUrl = URL.createObjectURL(blob)
        setLookImage(imageUrl)
      } else {
        console.error('Error reading the file.')
      }
    }
    fileReader.onerror = () => { }
    fileReader.readAsArrayBuffer(file)
  }

  const getPorcentage = () => {
    let completedLenght = 0
    let porcentageBase = 0
    if (active2 === false) {
      matchedUserData.forEach((prod) => {
        porcentageBase += 100 / prod.length
        prod.forEach((product: any) => {
          if (product.status === 'Aprovado') {
            completedLenght += 1
          }
        })
      })
      if (porcentageBase > 0 && completedLenght > 0) {
        setProgressbar(porcentageBase * completedLenght)
        setbarUpdate(barUpdate + 1)
        setActive2(true)
      }
    }
  }

  useEffect(() => {
    if (sendingMAil === true) {
      let foundUndesiredStatus = false
      documentsByMember.forEach((prod) => {
        prod.documents.forEach((product: any) => {
          if (product.deletedAt) {
            return
          }
          if (product.status === 'Rechazado' ||
            product.status === undefined) {
            foundUndesiredStatus = true
          }
          else if (product.status === 'Pendiente por consignar') {
            foundUndesiredStatus = true
          }
        })
      })
      if (!foundUndesiredStatus) {
        const email = user?.email === undefined ? '' : user.email
        SendMail(email)
      }
      setSendMAil(false)
    }
  }, [sendingMAil])

  useEffect(() => {
    if (active3 === true) {
      refetch()
      docRefetch()
      setActive4(0)
      setActive2(false)
      getPorcentage()
      setActive3(false)
    }
  }, [active3, completedLenght, getPorcentage, openForm, porcentageBase, refetch, docRefetch])

  useEffect(() => {
    getPorcentage()
  }, [getPorcentage, porcentageBase])

  const filterButton = (name: string) => {
    if (name === "all") {
      setAllDocuments(true)
      setPendingDocuments(false)
      setReviewingDocuments(false)
      setAprovedDocuments(false)
      setRejectedDocuments(false)
      return
    }
    if (name === "pending") {
      setAllDocuments(false)
      setPendingDocuments(true)
      setReviewingDocuments(false)
      setAprovedDocuments(false)
      setRejectedDocuments(false)
      return
    }
    if (name === "review") {
      setAllDocuments(false)
      setPendingDocuments(false)
      setReviewingDocuments(true)
      setAprovedDocuments(false)
      setRejectedDocuments(false)
      return
    }
    if (name === "aproved") {
      setAllDocuments(false)
      setPendingDocuments(false)
      setReviewingDocuments(false)
      setAprovedDocuments(true)
      setRejectedDocuments(false)
      return
    }
    if (name === "rejected") {
      setAllDocuments(false)
      setPendingDocuments(false)
      setReviewingDocuments(false)
      setAprovedDocuments(false)
      setRejectedDocuments(true)
      return
    }
  }
  const visitProducts = [
    'bb4056fb-2440-4a06-aec1-8d13349e7427', 'a10980f2-57f3-43c7-b863-a995915389de', '3f056a93-1f4b-4598-9aec-f929ecd00e97',
    'fd5b2b7d-8772-4ee6-91cc-53f710351021', '7664135d-5b88-4640-8bf1-6f56d7f2a78a', 'daa261eb-6263-4b6e-acbb-4343bd4ce35a',
    'cbdf9710-7c51-49b9-9e17-ced12c7c85c9', 'b0e0313b-6344-4733-90a0-6d6031f8be1e', '41669177-9782-4052-bb8d-08b6c29c928f'
  ]
  const studyProducts = [
    '94726637-76e7-438b-9494-99b136bbc7ea', 'bb4056fb-2440-4a06-aec1-8d13349e7427', 'b9ada976-a4fe-444d-9f6a-dd58c0928120',
    '8327593a-cc82-4703-afaa-72030f67c001', 'f9b3dcd2-b420-4cc0-b5df-575f9a5be911', '9d0fe0b9-4aa1-4b4d-ac85-4a9c5f36d800',
    '32549570-adf0-4add-ad43-5c49fed6566d', '7f7fdb45-7d67-4623-8b72-d0d15a1bf39c'
  ]
  const workProducts = [
    'd60d6643-8cee-4df2-bb67-ce10b40f6f61', '41b1a433-5900-4b96-85f3-7e2c8fce0a41', '50eb5404-9ae2-4655-91ae-89d2e8a6fa73',
    '19ca7bf2-6514-478b-bb78-c7861619fe39', 'e377ed33-a53a-4312-8842-1a689237b07c', '66cfa2b2-e51d-4758-8aef-2f4d8e158e8b',
    'e8e477d1-f126-4a64-93da-539b3715730d', '30a323ab-236c-461c-93ea-28308c8fafa6', '08e1143b-4f7f-48db-a289-9a3fc40f8037',
    'aa7e79b8-2219-4b68-aa98-5a212c5311fa', 'a645705f-f9f7-47c6-9319-5a1026da84d2', '6e420420-d96c-4cb8-a145-95a7964ed618',
    '9d3ab240-2e68-42b8-a7d6-88e22e9fe095', '9015fb11-d513-4f6e-9de9-aab92d5d2b42', '4f050e6f-e9eb-4ee0-b12c-465d2d715c16',
    'ad0b016e-0f09-4e80-ad6a-f1a78200aa3c', '0dac7746-02c0-47db-be28-14755d62a64d', 'c263052c-80cf-4fc3-a628-88e51621f4e3',
    '6420335e-30aa-46c5-892b-e1fa23914733', '52096218-da31-4e01-a406-0e06a9ca4d07', 'a6b8aaf0-6476-4f64-9a4c-c8fe153a06ac',
    '229d7b91-7a9b-407a-988b-9e45096bff5b', 'c4bb7c15-0e15-487f-b161-21d0426d82f9', 'f9c0635b-f57f-4bdb-9aa8-1e4fe0be8994',
    'c6df5f52-670d-4d7f-809e-72cb59fa684a', '8a29f12d-a898-4d52-8fd1-f184094cd8c3', '65245c09-8c4d-485a-abaa-8f752d4dfe39',
    'c8d8dce1-7959-4271-92c9-ddd6bbda35d4', '588bed15-e8fb-4216-8580-6de93f1512ae', '1d2b991b-7d75-4127-99d8-19f884efcfd0',
    'd65141bb-58c1-46f1-a1c7-d1132f64796f', 'd8e4d955-d3cd-4786-b9d2-bbe41e84a54f', 'b6f813c5-0911-4b86-be17-0e74bc332316'
  ]
  const residenceProducts = [
    'c7571404-31f4-45b3-898a-160d4e72afc5', '9665d0d1-c145-4dc6-be9f-d4c69006604c', '6f317d28-cab2-4316-9a25-3f62e4e047e6',
    '8b5628bb-15a8-44a9-a557-00d4f600cd1d', '3320f244-f338-43d0-8006-503003d16501', 'b58daf73-88be-41ee-8a0d-9e485748ced8',
    '7ac7912e-9514-4281-924b-1a36a4dd3065', '9c1f03d1-5100-4a50-8030-cfb8ebad4480', '48b466b6-d8e1-48f8-ae32-2877760f92b7',
    'd1195212-1cdc-4792-b88b-824922f63c36', '2a7387ab-c1dc-45f1-a6b3-3f22c0fc30e5', 'f455901e-a97e-4c2d-abd4-8a359778e88a',
    '068de1e8-3074-4259-8a2a-1418d8f39ca3', '87bcc196-0f68-421b-9591-4e3565d69783', '728d916e-47fe-48ae-9628-468712651938',
    '0c9f691d-2b23-4c7d-8419-7f55785418f6', 'fb1a576c-f5a1-4d72-9aa8-d2f8eb5fa34d', '1de57333-82cf-4aaa-8a9b-23fadab35a3f',
    '7b84fba5-c4a9-48bc-ac44-f84a97db6ea2', 'bdda48a1-ea4d-480d-9e2c-e14e7904dd27', '054e28db-5fa8-487b-a452-38b94392f1ce',
    'cd90d231-265a-4be0-9982-54cf3aee6537', '346454f5-f81f-4a37-abc6-8e6d17f26512', '29c268c2-b5a9-4888-82c9-a209b5e20962',
    'a9b2deee-cb6f-4aa0-a521-8ecc02d32394', '7d8df96c-dd71-48aa-b0f7-301e59706212', '03030060-aa44-452a-ad54-80fedf29a547',
    '52e5a255-5894-4f81-9e4b-7a59451ed83d', '5848c9c4-bfc9-49b0-881d-9cab020dd1e5', 'ee8c9b93-151c-40ce-86ca-8f8f2aa3a644',
    '1ec2dc58-5776-49d3-b487-f373ffd1e0a3', '4762b784-650d-429b-b48d-02c351f34085'
  ]
  const specialProducts = [
    '8dcb5115-bdce-45e3-8b50-d2d592020713',
    'f9fe4a37-7284-4bf7-a22e-f0f12678bb99'
  ]

  return (
    <div className='main-data-continer'>
      {showUserRepresentative !== "" && <>
        <div className='user-profile-popup'>
          <div className='user-profile-popup-continer'>
            <button
              style={{
                marginBottom: '10px',
              }}
              className='button-ui button-secondary '
              onClick={() => {
                setShowUserRepresentative("")
              }}>
              <p className='meet-text2' style={{ fontWeight: 'bold', margin: '0' }}>Volver</p>
            </button>
            <>
              <PdfView envelopeId={useOfRep} /></>
          </div>
        </div>
      </>}

      <> <div className='text-container'>
        <button
          className='button-volver-a-solicitudes'
          style={{ width: '15rem', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}
          onClick={() => { setStep(1) }}>
          <img src={Arrow} alt="Arrow" />
          <p style={{ margin: '0' }}>VOLVER A SOLICITUDES</p>
        </button>
        <div style={{ width: '100%', height: '1px', backgroundColor: '#DFE4EA', marginTop: '1rem', marginBottom: '1rem' }}></div>
        <div className='step-three-header-container'>
          <div>
            <h1 className='step-header-title mt-4' style={{ gap: '30px' }}>Contrato n° {contractId}
              <button
                style={{
                  //  color: "#01A9DB",
                  //  display: "flex",
                  //  flexDirection: "row",
                  //  gap: "0.3rem",
                  //  alignItems: "center",
                  //  justifyContent: "center",
                }}
                className='button-ui button-tertiary no-border'
                onClick={() => setIsModalVisible(true)}
              >
                <img src={Box} alt="Box" />
                <p style={{ margin: "0", fontSize: "1rem" }}>Ver detalles del contrato</p>
              </button>
            </h1>
            {contract.map((item: any) =>
              <>
                {item.envelope_id === contractId ? (
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginTop: '20px', alignItems: 'center' }}>
                    <p style={{ fontSize: '12px' }}>Contratado el {item.completedDate.substring(0, 10)}</p>
                    {(() => {
                      // Verificar si 'contracts_users_applicants' está definido
                      const applicants = item.applicants ? JSON.parse(item.applicants) : []
                      let showPlane = false
                      let showGraduate = false
                      let showBriefcase = false
                      let showHouse = false
                      let showUsersIcon2 = false
                      applicants.forEach((applicant: { productId: string }) => {
                        if (visitProducts.includes(applicant.productId)) showPlane = true
                        if (studyProducts.includes(applicant.productId)) showGraduate = true
                        if (workProducts.includes(applicant.productId)) showBriefcase = true
                        if (residenceProducts.includes(applicant.productId)) showHouse = true
                        if (specialProducts.includes(applicant.productId)) showUsersIcon2 = true
                      })
                      // Calcular los tipos de miembro únicos
                      const uniqueMemberTypesCount = applicants.reduce((acc: Record<string, boolean>, applicant: { memberType: string | number }) => {
                        acc[applicant.memberType] = true
                        return acc
                      }, {})
                      const uniqueMemberTypes = Object.keys(uniqueMemberTypesCount).length
                      return (
                        <div style={{ display: 'flex', gap: '10px', marginTop: '-14px' }}>
                          {showPlane && (
                            <button
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '3px 5px',
                                backgroundColor: '#01A9DB',
                                borderRadius: '3px',
                                gap: '5px'
                              }}>
                              <img src={Plane} alt="Plane Icon" />
                            </button>
                          )}
                          {showGraduate && (
                            <button
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '3px 5px',
                                backgroundColor: '#EF5DA8',
                                borderRadius: '3px',
                                gap: '5px'
                              }}>
                              <img src={Graduate} alt="Graduate Icon" />
                            </button>
                          )}

                          {showBriefcase && (
                            <button
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '3px 5px',
                                backgroundColor: '#22AD5C',
                                borderRadius: '3px',
                                gap: '5px'
                              }}>
                              <img src={Briefcase} alt="Briefcase Icon" />
                            </button>
                          )}

                          {showHouse && (
                            <button
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '3px 5px',
                                borderRadius: '3px',
                                gap: '5px',
                                width: '35px'
                              }}
                            >
                              <img src={Houseonly} alt="House Icon" />
                            </button>
                          )}

                          {showUsersIcon2 && (
                            <button
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '3px 5px',
                                backgroundColor: '#DFE4EA',
                                borderRadius: '3px',
                                gap: '5px'
                              }}
                            >
                              <img src={UsersIcon2} alt="Users Icon 2" />
                            </button>
                          )}

                          {/* Icono con el número de tipos de miembros únicos */}
                          <button
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '3px 5px',
                              border: '1px solid #DFE4EA',
                              borderRadius: '3px',
                              gap: '5px'
                            }}
                          >
                            <img src={UsersIcon} alt="User Icon" />
                            <p style={{ fontSize: '10px', fontWeight: 'bold', margin: '0' }}>{uniqueMemberTypes}</p>
                          </button>
                        </div>
                      )
                    })()}

                  </div>

                ) : null}

              </>)}

          </div>


          <a
            className='product-name btn-section help-step-btn'
            rel='noreferrer'
            target='_blank'
            href="https://comunidad.immilandcanada.com/t/visa-de-visitante-fuera-de-canada/47">
            <img src={Lifering} alt="Lifering" />
            <p style={{ margin: '0', fontSize: '14px', textDecoration: 'none', color: 'black' }}>Ayuda</p>
          </a>
        </div>
        <div className='onboard-guide-container'>
          {/* Timeline */}
          <div className='step-line-cont'>
            {step > 1 && (
              <div
                className='guide-number-done one-btn'
                onClick={() => {
                  setStep(1)
                }}
              >
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>1</b></p>
                <div className='step-number-checked data-final-step-check'>
                  <img style={{ width: '20px', height: '20px' }} src={Check} alt="Check" />
                </div>
                <p className='hide-tex step-text' style={{ color: '#13C296' }}>Servicio contratado</p>
                <div className='greenpath2' />
                <img className='data-final-step-chevron'
                  style={{ width: '20px', height: '20px' }}
                  src={Chevron2} alt="Chevron2" />
              </div>
            )}

            {(maxStep > 2 && step > 2) || (maxStep > 2 && step < 2) ? (
              <div
                className='guide-number-done two-btn'
                onClick={() => {
                  setStep(2)
                }}
              >
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>2</b></p>
                <div className='step-number-checked data-final-step-check'>
                  <img style={{ width: '20px', height: '20px' }} src={Check} alt="Check" />
                </div>
                <p className='hide-tex step-text' style={{ color: '#13C296' }}> Completar formularios</p>
                <div className='greenpath2' />
                <img className='data-final-step-chevron'
                  style={{ width: '20px', height: '20px' }}
                  src={Chevron2} alt="Chevron2" />
              </div>
            ) : step === 2 && (
              <>
                <div className='guide-number two-btn'>
                  <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>2</b></p>
                  <p className='hide-tex step-text' style={{ color: 'black' }}> Completar formularios</p>
                  <div className='blackpath' />
                  <img className='data-final-step-chevron'
                    style={{ width: '20px', height: '20px' }}
                    src={Chevron2} alt="Chevron2" />
                </div>
              </>
            )}

            {(maxStep > 3 && step > 3) || (maxStep >= 3 && step < 3) ? (
              <div
                className='guide-number-done three-btn'
                onClick={() => {
                  setStep(3)
                }}
              >
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>3</b></p>
                <p className='hide-tex step-text' style={{ color: '#13C296' }}> Adjuntar documentos</p>
                <div className='greenpath2' />
                <img className='data-final-step-chevron'
                  style={{ width: '20px', height: '20px' }}
                  src={Chevron2} alt="Chevron2" />
              </div>
            ) : step === 3 ? (
              <>
                <div className='guide-number three-btn'>
                  <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>3</b></p>
                  <p className='hide-tex step-text' style={{ color: 'black' }}> Adjuntar documentos</p>
                  <div className='blackpath' />
                  <img className='data-final-step-chevron'
                    style={{ width: '20px', height: '20px' }}
                    src={Chevron2} alt="Chevron2" />
                </div>

                <div className='responsive-badges'>
                  {numDraft > 0 &&
                    <div className='step-badge-rejected step-badge-rejected-dinamic rejected-s3-badge'>
                      <img src={Cross} alt="Cross" />
                      <div className='flex gap-1'>
                        <p className='step-badge-rejected-p-ones'><b>{numDraft}</b></p>
                        <p className='step-badge-rejected-p-ones hide-tex'>Rechazado(s)</p>
                      </div>
                    </div>}
                  {numPending > 0 &&
                    <div className='step-badge-active2 step-badge-rejected-dinamic pending-s3-badge' >
                      <img src={ClockY} alt="ClockY" />
                      <div className='flex gap-1'>
                        <p className='step-badge-active-p-ones' ><b>{numPending}</b></p>
                        <p className='step-badge-active-p-ones hide-tex'>Por adjuntar</p>
                      </div>
                    </div>}
                  {numReview > 0 &&
                    <div className='step-badge-active-draft2 step-badge-rejected-dinamic review-s3-badge'>
                      <img src={Eye} alt="Eye" />
                      <div className='flex gap-1'>
                        <p className='step-badge-active-draft-p-ones'><b>{numReview}</b></p>
                        <p className='step-badge-active-draft-p-ones hide-tex'
                        >Adjuntado(s)</p>
                      </div>
                    </div>
                  }</div>

              </>
            ) : (
              <div className='guide-number-deactivate three-btn'>
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>3</b></p>
                <p className='hide-tex step-text' style={{ color: '#d1d5db' }}> Adjuntar documentos</p>
                <div className='graypath' />
                <img className='data-final-step-chevron'
                  style={{ width: '20px', height: '20px' }}
                  src={Chevron2} alt="Chevron2" />
              </div>
            )}
            {(maxStep >= 5 && step > 4) || (maxStep >= 5 && step < 4) && (
              <div
                className='guide-number-done four-btn'
                onClick={() => {
                  setStep(4)
                }}>
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>4</b></p>
                <p className='hide-tex step-text' style={{ color: '#13C296' }}>Asignación y segimiento</p>
                <div className='greenpath2' />
                <img className='data-final-step-chevron'
                  style={{ width: '20px', height: '20px' }}
                  src={Chevron2} alt="Chevron2" />
              </div>)}
            {step === 4 ? (
              <div className='guide-number four-btn'>
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>4</b></p>
                <p className='hide-tex step-text' style={{ color: 'black' }}>Asignación y segimiento</p>
                <div className='blackpath' style={{ top: '66px', left: '-8px', height: '5.5rem' }} />
                <img className='data-final-step-chevron'
                  style={{ width: '20px', height: '20px' }}
                  src={Chevron2} alt="Chevron2" />
              </div>
            ) : null}
            {(maxStep < 5) && (
              <div className='guide-number-deactivate four-btn'>
                <p className='white-text' style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>4</b></p>
                <p className='hide-tex step-text' style={{ color: '#d1d5db' }}>Asignación y segimiento</p>
                <div className='graypath' />
                <img className='data-final-step-chevron'
                  style={{ width: '20px', height: '20px' }}
                  src={Chevron2} alt="Chevron2" />
              </div>)}

            {maxStep >= 5 && step < 5 && (
              <div
                className='guide-number-done five-btn'
                onClick={() => {
                  setStep(5)
                }}>
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>5</b></p>
                <p className='hide-tex step-text' style={{ color: '#13C296' }}>Confirmar cartas</p>
              </div>)}
            {step === 5 && (
              <div className='guide-number five-btn'>
                <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>5</b></p>
                <p className='hide-tex step-text' style={{ color: 'black' }}>Confirmar cartas</p>
              </div>)}
            {maxStep < 5 &&
              <div className='guide-number-deactivate five-btn'>
                <p className='white-text' style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>5</b></p>
                <p className='hide-tex step-text' style={{ color: '#d1d5db' }}>Confirmar cartas</p>
              </div>}
          </div>
          {openForm === false ? (
            <div className='step-three-main-container '>
              <h1 className='step-header' >Adjuntar documentos</h1>
              <p className='step-header-subtitle'>
                Adjunte sus documentos de soporte ¡Está a un paso de completar su solicitud!
              </p>
              {/* rejacted docs */}
              {numDraft > 0 &&
                <div className="rejected-doc">
                  <div className="flex flex-row items-center gap-4">
                    <div className="checkbox-red-continer">
                      <div className="checkbox-red">
                        X
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <p style={{ color: '#BC1C21', fontSize: '16px' }}>
                        Tiene {numDraft} formulario(s) rechazado(s). Resuélvalo para continuar con su proceso.
                      </p>
                    </div>
                  </div>
                </div>}
              <div className='flex flex-col'>
                <div className='flex flex-row gap-4 mt-4 mobile-styles'>
                  <button className='btn-section' onClick={() => (filterButton("all"))}>
                    <p>Ver todos</p>
                  </button>
                  <button className='btn-section' onClick={() => (filterButton("pending"))} >
                    <p>Por adjuntar</p>
                    <p className='amount pending'>{numPending >= 0 ? numPending : 0}</p>
                  </button>
                  <button className='btn-section' onClick={() => (filterButton("review"))}>
                    <p>Adjuntado</p>
                    <p className='amount review'>{numReview >= 0 ? numReview : 0}</p>
                  </button>
                  {numAproved > 0 &&
                    <button className='btn-section' onClick={() => (filterButton("aproved"))}>
                      <p>Aprobados</p>
                      <p className='amount approved'>{numAproved}</p>
                    </button>}
                  {numDraft > 0 &&
                    <button className='btn-section' onClick={() => (filterButton("rejected"))}>
                      <p>Rechazados</p>
                      <p className='amount rejected'>{numDraft}</p>
                    </button>}
                </div>
                <div className='flex flex-row justify-between'>
                  <div>
                    <div className='representation-container'>
                    </div>
                  </div>
                </div>
              </div>
              {lookDocument ? (
                <div className='user-profile-popup'>
                  <div className='user-profile-popup-continer'>
                    <div style={{ marginTop: '1rem' }}>
                      <DocumentView document={lookDocument} setLookDocument={setLookDocument} />
                    </div>
                  </div>
                </div>
              ) : lookImage ? (
                <div className='user-profile-popup'>
                  <div className='user-profile-popup-continer'>
                    <div style={{ marginTop: '1rem' }}>
                      <PhotoView image={lookImage} setLookImage={setLookImage} />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <ul className='uploader-documents-continer '>
                    {documentsByMember &&
                      active4 > 0 &&
                      data &&

                      documentsByMember?.sort((a: any, b: any) => {
                        // Ordenar por memberType, priorizando 'Solicitante principal'
                        if (a.memberType === 'Solicitante principal' && b.memberType !== 'Solicitante principal') {
                          return -1
                        } else if (a.memberType !== 'Solicitante principal' && b.memberType === 'Solicitante principal') {
                          return 1
                        } else if (a.memberType === 'Conyuge o Pareja de hecho' && b.memberType !== 'Conyuge o Pareja de hecho') {
                          return -1
                        } else if (a.memberType !== 'Conyuge o Pareja de hecho' && b.memberType === 'Conyuge o Pareja de hecho') {
                          return 1
                        } else {
                          return 0
                        }
                      })
                        .map((member: any, index: number) => (
                          <>
                            <div className={`step2-aplicant-card ${buttonStates === index ? 'open' : ''}`}>
                              <div onClick={() => handleToggleContent(index)} style={{ cursor: 'pointer', paddingBottom: '0.5rem' }} className='flex gap-4 mt-2 items-center justify-between w-full'>
                                <div style={{ width: '100%' }} className='flex gap-2 items-center'>
                                  <div>
                                    <div style={{ width: '100%' }} className='flex gap-4 items-center'>
                                      <p style={{ fontSize: '18px', margin: '0' }}>
                                        <b>{member.memberName}</b> ({member.memberType})
                                      </p>
                                    </div>
                                    <b style={{ fontSize: '12px' }}>{member.productName} </b>
                                  </div>
                                </div>
                                <button className={buttonStates === index ? 'show-btn-presed' : 'show-btn-presed2'}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleToggleContent(index)
                                  }}>
                                  <img className='show-btn' src={ChevronRight} alt='ChevronRight' />
                                </button>
                              </div>
                              <>
                                {buttonStates === index && (
                                  <>
                                    {member.documents.map((document: any) => (
                                      <>
                                        {document.status && !document.deletedAt ?
                                          <>
                                            {document.status === 'Rechazado' && (allDocuments === true || rejectedDocuments === true) ?
                                              <>
                                                <li className='uploader-documents-item' style={{ border: '1px solid #F23030', listStyle: 'none' }}>
                                                  <div className='uploader-documents-item-maindiv '>
                                                    <div className='flex flex-col w-full'>
                                                      <div className='flex gap-4'>
                                                        <div className='flex items-center gap-4 justify-between w-full'>
                                                          <div className='flex flex-col'>
                                                            <p className='uploader-documents-title'>
                                                              {document.document.name}
                                                            </p>
                                                            <p className='uploader-documents-description'>
                                                              {document.document.description}
                                                            </p>
                                                          </div>
                                                          <div className='flex gap-4 aling-rejected-icon' style={{ width: '4rem' }}>
                                                            <div className='step-badge-rejected' style={{ width: '6rem', height: '1.5rem', marginRight: '10px' }}>
                                                              <p style={{ fontSize: '12px' }}
                                                                className='step-badge-rejected-p-ones'>Rechazado</p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='flex flex-col gap-4'>
                                                    <div className='flex flex-col gap-4 w-full'>
                                                      <div className='flex gap-2'>
                                                        {document.document.translate === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Requiere traducción </p>
                                                          </div>) : null}
                                                        {document.document.legalize === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Requiere legalización </p>
                                                          </div>) : null}
                                                      </div>
                                                      <div className="rejected-doc" style={{ width: '100%' }}>
                                                        <div className="flex flex-row items-center gap-4">
                                                          <div className="flex flex-col">
                                                            <p style={{ color: '#BC1C21', fontSize: '16px', margin: '0' }}><b>Motivo del rechazo:</b></p>
                                                            <p style={{ color: '#BC1C21', fontSize: '16px' }}>{document.comments}</p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='uploader-documents-buttons-container'>
                                                      <div style={{ flex: '2', }}>
                                                        <div className='uploader-popup-box'>
                                                          {files !== null && (documentSelected === document.id) ? (
                                                            <div className='flex flex-col items-center gap-2'>
                                                              <div className="filename">{files.name}</div>
                                                            </div>
                                                          ) : (
                                                            <>
                                                              <input
                                                                className='uploader_input'
                                                                type='file'
                                                                accept='.pdf,.jpeg,.jpg,.png'
                                                                max={1}
                                                                onChange={(event: any) => {
                                                                  const file = event.target.files[0]
                                                                  setDocumentId(document.document.id)
                                                                  setProductId(document.product.id)
                                                                  document.document.comments !== null
                                                                    ? setDocumentDisclaimer(
                                                                      document.document.comments
                                                                    )
                                                                    : setDocumentDisclaimer('')
                                                                  if (file) {
                                                                    const allowedExtensions = [
                                                                      'pdf',
                                                                      'jpeg',
                                                                      'jpg',
                                                                      'png',
                                                                    ]
                                                                    const fileExtension = file.name
                                                                      .split('.')
                                                                      .pop()
                                                                      ?.toLowerCase()
                                                                    if (
                                                                      fileExtension &&
                                                                      !allowedExtensions.includes(
                                                                        fileExtension
                                                                      )
                                                                    ) {
                                                                      if (file) {
                                                                        const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png']
                                                                        const fileExtension = file.name
                                                                          .split('.')
                                                                          .pop()
                                                                          ?.toLowerCase()
                                                                        if (
                                                                          fileExtension &&
                                                                          !allowedExtensions.includes(
                                                                            fileExtension
                                                                          )
                                                                        ) {
                                                                          Swal.fire({
                                                                            title:
                                                                              'error solo archivos .pdf, .jpeg, .jpg, .png son aceptados',
                                                                            icon: 'error',
                                                                            showConfirmButton: false,
                                                                            timer: 1500,
                                                                          })
                                                                          event.target.value = null
                                                                          return
                                                                        }
                                                                      }
                                                                    }
                                                                    const maxFileSizeInBytes = 1.5 * 1024 * 1024
                                                                    if (file.size > maxFileSizeInBytes) {
                                                                      Swal.fire({
                                                                        title:
                                                                          'Error: El tamaño del archivo no puede ser mayor a 1.5 MB',
                                                                        icon: 'error',
                                                                        showConfirmButton: false,
                                                                        timer: 1500,
                                                                      })
                                                                      event.target.value = null
                                                                      return
                                                                    }
                                                                  }
                                                                  setDocumentSelected(document.id)
                                                                  setFiles(file)
                                                                }}
                                                              />
                                                            </>
                                                          )}
                                                          {files === null ? (
                                                            <></>
                                                          ) : (
                                                            <>
                                                              {document.id === documentSelected &&
                                                                <div className='flex flex-col'>
                                                                  <div className='flex gap-2'>
                                                                    {documentDisclaimer === '' ?
                                                                      <div className='flex flex-col items-center'>
                                                                        <button
                                                                          style={{
                                                                            flexDirection: 'column',
                                                                            display: 'flex',
                                                                            width: '58px',
                                                                            height: '40px',
                                                                            padding: '10px 15px',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            borderRadius: '30px',
                                                                            border: '1px solid #FFF',
                                                                            background: '#374151',
                                                                            boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)'
                                                                          }}
                                                                          type='button'
                                                                          onClick={() => {
                                                                            setDocumentId('')
                                                                            setProductId('')
                                                                            handleUpload(documentId, productId, member.memberName, member.memberType)
                                                                          }}
                                                                        >
                                                                          <img style={{ width: '15px', height: '15px' }} src={Upload} alt="Upload" />
                                                                          <a style={{ color: 'white', fontSize: '8px' }}> Subir</a>
                                                                        </button>

                                                                      </div>
                                                                      : (
                                                                        <div className='flex flex-col items-center'>
                                                                          <button
                                                                            style={{
                                                                              flexDirection: 'column',
                                                                              display: 'flex',
                                                                              width: '58px',
                                                                              height: '40px',
                                                                              padding: '10px 15px',
                                                                              justifyContent: 'center',
                                                                              alignItems: 'center',
                                                                              borderRadius: '30px',
                                                                              border: '1px solid #FFF',
                                                                              background: '#374151',
                                                                              boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)'
                                                                            }}
                                                                            type='button'
                                                                            className='uploader-documents-buttons-available'
                                                                            onClick={() => {
                                                                              setDisplayPopup3(true)
                                                                              setActive5(active5 + 1)
                                                                            }}
                                                                          >
                                                                            <img style={{ width: '15px', height: '15px' }} src={Upload} alt="Upload" />
                                                                            <a style={{ color: 'white', fontSize: '8px' }}> Subir</a>
                                                                          </button>

                                                                        </div>
                                                                      )}
                                                                    {displayPopup3 === true ? (
                                                                      <div className='disclaimer-popup'>
                                                                        <div className='uploader-popup'>
                                                                          <img
                                                                            style={{
                                                                              width: '80px',
                                                                              height: '80px',
                                                                              border: '2px solid #bf3c3c',
                                                                              padding: '1rem',
                                                                              borderRadius: '100%',
                                                                              marginBottom: '2rem',
                                                                            }}
                                                                            src='https://img.icons8.com/ios-filled/50/bf3c3c/exclamation-mark.png'
                                                                            alt='exclamation-mark'
                                                                          />
                                                                          <b>{documentDisclaimer}</b>
                                                                          <div className='flex gap-2'>
                                                                            <button
                                                                              style={{
                                                                                top: '0',
                                                                                marginTop: '2rem',
                                                                                width: '5rem',
                                                                              }}
                                                                              type='button'
                                                                              className='uploader-documents-buttons-available '
                                                                              onClick={() => {
                                                                                setDisplayPopup3(false)
                                                                                setDocumentDisclaimer('')
                                                                                setDocumentId('')
                                                                                setProductId('')
                                                                                handleUpload(documentId, productId, member.memberName, member.memberType)
                                                                              }}>
                                                                              Acepto
                                                                            </button>
                                                                            <button
                                                                              style={{
                                                                                top: '0',
                                                                                marginTop: '2rem',
                                                                                width: '5rem',
                                                                              }}
                                                                              className='uploader-documents-buttons-available '
                                                                              type='button'
                                                                              onClick={() => {
                                                                                setFiles(null)
                                                                                setDocumentDisclaimer('')
                                                                                setDisplayPopup3(false)
                                                                                refetch()
                                                                                docRefetch()
                                                                                setTimeout(() => {
                                                                                  refetch()
                                                                                  docRefetch()
                                                                                  setActive4(0)
                                                                                }, 300)
                                                                              }}>
                                                                              Cancelar
                                                                            </button>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    ) : null}
                                                                    <div className='flex flex-col items-center'>
                                                                      <button
                                                                        style={{
                                                                          display: 'flex',
                                                                          width: '58px',
                                                                          height: '40px',
                                                                          padding: '4px 5px',
                                                                          justifyContent: 'center',
                                                                          alignItems: 'center',
                                                                          flexDirection: 'column',
                                                                          borderRadius: '30px',
                                                                          border: '1px solid #FFF',
                                                                          background: '#8899A8',
                                                                          boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)'
                                                                        }}
                                                                        type='button'
                                                                        onClick={() => {
                                                                          setFiles(null)
                                                                          setDocumentDisclaimer('')
                                                                          refetch()
                                                                          docRefetch()
                                                                          setTimeout(() => {
                                                                            refetch()
                                                                            docRefetch()
                                                                            setActive4(0)
                                                                          }, 300)
                                                                        }}>
                                                                        <img style={{ width: '15px', height: '15px' }} src={Delete} alt="Delete" />
                                                                        <a style={{ color: 'white', fontSize: '8px' }}> Eliminar</a>
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              } </>)}
                                                        </div>
                                                      </div>
                                                      {document.document.translate === true ? (
                                                        <div className='powerup-card flex gap-2' style={{ backgroundColor: 'white', width: '12rem', borderRadius: '10px', border: '2px solid  #DFE4EA', background: '#FFF' }}>
                                                          <div style={{ backgroundColor: '#FBBF24', display: 'flex', padding: '12px 7px', flexDirection: 'column', alignItems: 'center', gap: '10px', alignSelf: 'stretch', borderRadius: '8px 0 0 8px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                          </div>
                                                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            <p style={{ fontSize: '16px', marginBottom: '12px' }}>Traducir desde</p>
                                                            <button
                                                              onClick={handleTranslationClick}
                                                              className='powerup-card-btn'
                                                            >
                                                              <img src={Car} alt="Car" />
                                                              <p style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>CAD$ 37</p>
                                                            </button>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                      {document.document.legalize === true ? (
                                                        <div className='uploader-popup-container uploader-popup-container-blue'>
                                                          <div style={{ backgroundColor: '#8099EC', display: 'flex', padding: '12px 7px', flexDirection: 'column', alignItems: 'center', gap: '10px', alignSelf: 'stretch', borderRadius: '8px 0 0 8px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                          </div>
                                                          <div className='text-manager'>
                                                            <p className='hide-tex' style={{ fontSize: '16px' }}>Legalizar desde</p>
                                                            <p className='hide-tex2' style={{ fontSize: '16px', margin: '0' }}><b>Legalizar</b></p>
                                                            <a
                                                              style={{ width: 'fit-content', paddingLeft: '1rem', paddingRight: '1rem' }}
                                                              target='_blank'
                                                              href='https://www.immilandcanada.com/migracion/affidavit'
                                                              className='powerup-card-btn'
                                                              rel='noreferrer'
                                                            >
                                                              <img src={Car} alt="Car" />
                                                              <p className='hide-tex' style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>CAD$ 80</p>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </li>

                                              </>
                                              : null}
                                            {document.status === 'Pendiente por consignar' && !document.deletedAt && (pendingDocuments === true || allDocuments === true) ? (
                                              <>
                                                <li className='uploader-documents-item' style={{ listStyle: 'none' }}>
                                                  <div className='uploader-documents-item-maindiv '>
                                                    <div className='flex flex-col'>
                                                      <p className='uploader-documents-title'>
                                                        {document.document.name}
                                                      </p>
                                                      <p className='uploader-documents-description'>
                                                        {document.document.description}
                                                      </p>
                                                    </div>
                                                    <div className='flex gap-16'>
                                                      <div className='flex gap-4'>
                                                        <div className='step-badge-active2' style={{ width: '6rem', height: '1.5rem' }}>
                                                          <p className='step-badge-active-p-ones'>Por adjuntar</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='flex flex-col gap-4'>
                                                    <div className='flex flex-col gap-4 w-full'>
                                                      <div className='flex gap-2'>
                                                        {document.document.translate === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Requiere traducción </p>
                                                          </div>) : null}
                                                        {document.document.legalize === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Requiere legalización </p>
                                                          </div>) : null}
                                                      </div>
                                                    </div>
                                                    <div className='uploader-documents-buttons-container'>
                                                      <div style={{ flex: '2', }}>
                                                        <div className='uploader-popup-box'>
                                                          {files !== null && (documentSelected === document.id) ? (
                                                            <div className='flex flex-col items-center gap-2'>
                                                              <div className="filename">{files.name}</div>
                                                            </div>
                                                          ) : (
                                                            <>
                                                              <input
                                                                className='uploader_input'
                                                                type='file'
                                                                accept='.pdf,.jpeg,.jpg,.png'
                                                                max={1}
                                                                onChange={(event: any) => {
                                                                  const file = event.target.files[0]
                                                                  setDocumentId(document.document.id)
                                                                  setProductId(document.product.id)
                                                                  document.document.comments !== null
                                                                    ? setDocumentDisclaimer(
                                                                      document.document.comments
                                                                    )
                                                                    : setDocumentDisclaimer('')

                                                                  if (file) {
                                                                    const allowedExtensions = [
                                                                      'pdf',
                                                                      'jpeg',
                                                                      'jpg',
                                                                      'png',
                                                                    ]
                                                                    const fileExtension = file.name
                                                                      .split('.')
                                                                      .pop()
                                                                      ?.toLowerCase()
                                                                    if (
                                                                      fileExtension &&
                                                                      !allowedExtensions.includes(
                                                                        fileExtension
                                                                      )
                                                                    ) {
                                                                      if (file) {
                                                                        const allowedExtensions = [
                                                                          'pdf',
                                                                          'jpeg',
                                                                          'jpg',
                                                                          'png',
                                                                        ]
                                                                        const fileExtension = file.name
                                                                          .split('.')
                                                                          .pop()
                                                                          ?.toLowerCase()
                                                                        if (
                                                                          fileExtension &&
                                                                          !allowedExtensions.includes(
                                                                            fileExtension
                                                                          )
                                                                        ) {
                                                                          Swal.fire({
                                                                            title:
                                                                              'error solo archivos .pdf, .jpeg, .jpg, .png son aceptados',
                                                                            icon: 'error',
                                                                            showConfirmButton: false,
                                                                            timer: 1500,
                                                                          })
                                                                          event.target.value = null
                                                                          return
                                                                        }
                                                                      }
                                                                    }
                                                                    const maxFileSizeInBytes =
                                                                      1.5 * 1024 * 1024
                                                                    if (file.size > maxFileSizeInBytes) {
                                                                      Swal.fire({
                                                                        title:
                                                                          'Error: El tamaño del archivo no puede ser mayor a 1.5 MB',
                                                                        icon: 'error',
                                                                        showConfirmButton: false,
                                                                        timer: 1500,
                                                                      })
                                                                      event.target.value = null
                                                                      return
                                                                    }
                                                                  }
                                                                  setDocumentSelected(document.id)
                                                                  setFiles(file)
                                                                }} />
                                                            </>
                                                          )}
                                                          {files === null ? (
                                                            <></>
                                                          ) : (
                                                            <>
                                                              {document.id === documentSelected &&
                                                                <div className='flex flex-col'>
                                                                  <div className='flex gap-1'>
                                                                    {documentDisclaimer === '' ?
                                                                      <div className='flex flex-col items-center'>
                                                                        <button
                                                                          style={{
                                                                            flexDirection: 'column',
                                                                            display: 'flex',
                                                                            width: '58px',
                                                                            height: '40px',
                                                                            padding: '10px 15px',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            borderRadius: '30px',
                                                                            border: '1px solid #FFF',
                                                                            background: '#374151',
                                                                            boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)'
                                                                          }}
                                                                          type='button'
                                                                          onClick={() => {
                                                                            setDocumentId('')
                                                                            setProductId('')
                                                                            handleUpload(documentId, productId, member.memberName, member.memberType)
                                                                          }}>
                                                                          <img style={{ width: '15px', height: '15px' }} src={Upload} alt="Upload" />
                                                                          <a style={{ color: 'white', fontSize: '8px' }}> Subir</a>
                                                                        </button>

                                                                      </div>
                                                                      : (
                                                                        <div className='flex flex-col items-center'>
                                                                          <button
                                                                            style={{
                                                                              flexDirection: 'column',
                                                                              display: 'flex',
                                                                              width: '58px',
                                                                              height: '40px',
                                                                              padding: '10px 15px',
                                                                              justifyContent: 'center',
                                                                              alignItems: 'center',
                                                                              borderRadius: '30px',
                                                                              border: '1px solid #FFF',
                                                                              background: '#374151',
                                                                              boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)'
                                                                            }}
                                                                            type='button'
                                                                            className='uploader-documents-buttons-available'
                                                                            onClick={() => {
                                                                              setDisplayPopup3(true)
                                                                              setActive5(active5 + 1)
                                                                            }}
                                                                          >
                                                                            <img style={{ width: '15px', height: '15px' }} src={Upload} alt="Upload" />
                                                                            <a style={{ color: 'white', fontSize: '8px' }}> Subir</a>
                                                                          </button>
                                                                        </div>
                                                                      )}
                                                                    {displayPopup3 === true ? (
                                                                      <div className='disclaimer-popup'>
                                                                        <div className='uploader-popup'>
                                                                          <img
                                                                            style={{
                                                                              width: '80px',
                                                                              height: '80px',
                                                                              border: '2px solid #bf3c3c',
                                                                              padding: '1rem',
                                                                              borderRadius: '100%',
                                                                              marginBottom: '2rem',
                                                                            }}
                                                                            src='https://img.icons8.com/ios-filled/50/bf3c3c/exclamation-mark.png'
                                                                            alt='exclamation-mark' />
                                                                          <b>{documentDisclaimer}</b>
                                                                          <div className='flex gap-2'>
                                                                            <button
                                                                              style={{
                                                                                top: '0',
                                                                                marginTop: '2rem',
                                                                                width: '5rem',
                                                                              }}
                                                                              type='button'
                                                                              className='uploader-documents-buttons-available '
                                                                              onClick={() => {
                                                                                setDisplayPopup3(false)
                                                                                setDocumentDisclaimer('')
                                                                                setDocumentId('')
                                                                                setProductId('')
                                                                                handleUpload(documentId, productId, member.memberName, member.memberType)
                                                                              }}>
                                                                              Acepto
                                                                            </button>
                                                                            <button
                                                                              style={{
                                                                                top: '0',
                                                                                marginTop: '2rem',
                                                                                width: '5rem',
                                                                              }}
                                                                              className='uploader-documents-buttons-available '
                                                                              type='button'
                                                                              onClick={() => {
                                                                                setFiles(null)
                                                                                setDocumentDisclaimer('')
                                                                                setDisplayPopup3(false)
                                                                                refetch()
                                                                                docRefetch()
                                                                                setTimeout(() => {
                                                                                  refetch()
                                                                                  docRefetch()
                                                                                  setActive4(0)
                                                                                }, 300)
                                                                              }}>
                                                                              Cancelar
                                                                            </button>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    ) : null}
                                                                    <div className='flex flex-col items-center'>
                                                                      <button
                                                                        style={{
                                                                          display: 'flex',
                                                                          flexDirection: 'column',
                                                                          width: '58px',
                                                                          height: '40px',
                                                                          padding: '6px 3px',
                                                                          justifyContent: 'center',
                                                                          alignItems: 'center',
                                                                          borderRadius: '30px',
                                                                          border: '1px solid #FFF',
                                                                          background: '#8899A8',
                                                                          boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)'
                                                                        }}
                                                                        type='button'
                                                                        onClick={() => {
                                                                          setFiles(null)
                                                                          setDocumentDisclaimer('')
                                                                          refetch()
                                                                          docRefetch()
                                                                          setTimeout(() => {
                                                                            refetch()
                                                                            docRefetch()
                                                                            setActive4(0)
                                                                          }, 300)
                                                                        }}>
                                                                        <img style={{ width: '15px', height: '15px' }} src={Delete} alt="Delete" />
                                                                        <a style={{ color: 'white', fontSize: '8px' }}> Eliminar</a>
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              } </>)}
                                                        </div>
                                                      </div>
                                                      {document.document.translate === true ? (
                                                        <div className='flex gap-2' style={{ backgroundColor: 'white', width: '12rem', borderRadius: '10px', border: '2px solid  #DFE4EA', background: '#FFF' }}>
                                                          <div style={{ backgroundColor: '#FBBF24', display: 'flex', padding: '12px 7px', flexDirection: 'column', alignItems: 'center', gap: '10px', alignSelf: 'stretch', borderRadius: '8px 0 0 8px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                          </div>
                                                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            <p style={{ fontSize: '16px' }}>Traducir desde</p>
                                                            <button
                                                              onClick={handleTranslationClick}
                                                              className='powerup-card-btn'>
                                                              <img src={Car} alt="Car" />
                                                              <p style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>CAD$ 37</p>
                                                            </button>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                      {document.document.legalize === true ? (
                                                        <div className='uploader-popup-container uploader-popup-container-blue'>
                                                          <div style={{ backgroundColor: '#8099EC', display: 'flex', padding: '12px 7px', flexDirection: 'column', alignItems: 'center', gap: '10px', alignSelf: 'stretch', borderRadius: '8px 0 0 8px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                          </div>
                                                          <div className='text-manager'>
                                                            <p className='hide-tex' style={{ fontSize: '16px' }}>Legalizar desde</p>
                                                            <p className='hide-tex2' style={{ fontSize: '16px', margin: '0' }}><b>Legalizar</b></p>
                                                            <a
                                                              style={{ width: 'fit-content', paddingLeft: '1rem', paddingRight: '1rem' }}
                                                              target='_blank'
                                                              href='https://www.immilandcanada.com/migracion/affidavit'
                                                              className='powerup-card-btn'
                                                              rel='noreferrer'
                                                            >
                                                              <img src={Car} alt="Car" />
                                                              <p className='hide-tex' style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>CAD$ 80</p>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </li>
                                              </>
                                            ) : null}
                                            {document.status === 'Aprovado' && (aprovedDocuments === true || allDocuments === true) ? (
                                              <li className='uploader-documents-item' style={{ listStyle: 'none' }}>
                                                <div className='uploader-documents-item-maindiv '>
                                                  <div className='flex items-center justify-between gap-4 w-full'>
                                                    <div className='flex flex-col'>
                                                      <p className='uploader-documents-title'>
                                                        {document.document.name}
                                                      </p>
                                                      <p className='uploader-documents-description' >
                                                        {document.document.description}
                                                      </p>
                                                    </div>
                                                    <div className='step-badge-active-success2' style={{ width: '6rem', height: '1.5rem' }}>
                                                      <p className='step-badge-active-success-p-ones' style={{ fontSize: '12px', margin: '0' }}>Aprobado</p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className='flex flex-col gap-4'>
                                                  <div className='flex gap-2'>
                                                    {document.document.translate === true ? (
                                                      <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                        <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                        <p style={{ margin: '0', fontSize: '10px' }}>Requiere traducción </p>
                                                      </div>) : null}

                                                    {document.document.legalize === true ? (
                                                      <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                        <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                        <p style={{ margin: '0', fontSize: '10px' }}>Requiere legalización </p>
                                                      </div>) : null}
                                                  </div>
                                                  {document.file.mimetype === 'application/pdf' ? (
                                                    <div
                                                      onClick={() => {
                                                        convertDocument(document.file.id as string)
                                                      }}
                                                      className='view-documents-uploaded'>
                                                      <p style={{ color: '#0B76B7', margin: '0', fontSize: '12px' }}>{document.file.name}</p>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      onClick={(e) => {
                                                        convertImage(document.file.id as string)
                                                      }}
                                                      className='view-documents-uploaded'>
                                                      <p style={{ color: '#0B76B7', margin: '0', fontSize: '12px' }}>{document.file.name}</p>
                                                    </div>
                                                  )}
                                                </div>
                                              </li>
                                            ) : null}
                                            {document.status === 'Pendiente por revisión' && !document.deletedAt && (allDocuments === true || reviewingDocuments === true) ? (
                                              <li className='uploader-documents-item' style={{ listStyle: 'none' }}>
                                                <div className='uploader-documents-item-maindiv '>
                                                  <div className='flex items-center justify-between gap-4 w-full'>
                                                    <div>
                                                      <p className='uploader-documents-title'>
                                                        {document.document.name}
                                                      </p>
                                                      <p className='uploader-documents-description' >
                                                        {document.document.description}
                                                      </p>
                                                    </div>
                                                    <div className='step-badge-active-draft2' style={{ border: '1px solid #8899A8' }}>
                                                      <img style={{ width: '16px', height: '16px' }} src={Clock} alt="Clock" />
                                                      <p className='step-badge-active-draft-p-ones hide-tex' style={{ fontSize: '12px', margin: '0' }}>En revisión</p>
                                                      <p className='step-badge-active-draft-p-ones hide-tex2' style={{ fontSize: '12px' }}>En revisión</p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className='flex flex-col gap-4'>
                                                  <div className='flex gap-2'>
                                                    {document.document.translate === true ? (
                                                      <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                        <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                        <p style={{ margin: '0', fontSize: '10px' }}>Requiere traducción </p>
                                                      </div>) : null}
                                                    {document.document.legalize === true ? (
                                                      <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                        <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                        <p style={{ margin: '0', fontSize: '10px' }}>Requiere legalización </p>
                                                      </div>) : null}
                                                  </div>
                                                  {document.file.mimetype === 'application/pdf' ? (
                                                    <div
                                                      onClick={() => {
                                                        convertDocument(document.file.id as string)
                                                      }}
                                                      className='view-documents-uploaded'>
                                                      <p style={{ color: '#0B76B7', margin: '0', fontSize: '12px' }}>{document.file.name}</p>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      onClick={(e) => {
                                                        convertImage(document.file.id as string)
                                                      }}
                                                      className='view-documents-uploaded'>
                                                      <p style={{ color: '#0B76B7', margin: '0', fontSize: '12px' }}>{document.file.name}</p>
                                                    </div>
                                                  )}
                                                </div>
                                              </li>
                                            ) : null}
                                          </>
                                          :
                                          <>
                                            {document.document.statusDoc === 'Pendiente por consignar' && !document.deletedAt && (pendingDocuments === true || allDocuments === true) ? (
                                              <>
                                                <li className='uploader-documents-item' style={{ listStyle: 'none' }}>
                                                  <div className='uploader-documents-item-maindiv'>
                                                    <div>
                                                      <p className='uploader-documents-title'>
                                                        {document.document.name}
                                                      </p>
                                                      <p className='uploader-documents-description' >
                                                        {document.document.description}
                                                      </p>
                                                    </div>
                                                    <div className='flex gap-16'>
                                                      <div className='flex gap-4'>
                                                        <div className='step-badge-active2' style={{ width: '6rem', height: '1.5rem' }}>
                                                          <p className='step-badge-active-p-ones'>Por adjuntar</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='flex flex-col gap-4'>
                                                    <div className='flex flex-col gap-1 w-full'>
                                                      <div className='flex gap-2'>
                                                        {document.document.translate === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Requiere traducción </p>
                                                          </div>) : null}

                                                        {document.document.legalize === true ? (
                                                          <div className='flex gap-1 items-center' style={{ backgroundColor: 'white', border: '1px solid #DFE4EA', borderRadius: '3px', padding: '3px 5px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                            <p style={{ margin: '0', fontSize: '10px' }}>Requiere legalización </p>
                                                          </div>) : null}
                                                      </div>
                                                    </div>
                                                    <div className='uploader-documents-buttons-container'>
                                                      <div style={{ flex: '2', }}>
                                                        <div className='uploader-popup-box'>
                                                          {files !== null && (documentSelected === document.id) ? (
                                                            <div className='flex flex-col items-center gap-2'>
                                                              <div className="filename">{files.name}</div>
                                                            </div>
                                                          ) : (
                                                            <>
                                                              <input
                                                                className='uploader_input'
                                                                type='file'
                                                                accept='.pdf,.jpeg,.jpg,.png'
                                                                max={1}
                                                                onChange={(event: any) => {
                                                                  const file = event.target.files[0]
                                                                  setDocumentId(document.document.id)
                                                                  setProductId(document.product.id)
                                                                  document.document.comments !== null
                                                                    ? setDocumentDisclaimer(
                                                                      document.document.comments
                                                                    )
                                                                    : setDocumentDisclaimer('')

                                                                  if (file) {
                                                                    const allowedExtensions = [
                                                                      'pdf',
                                                                      'jpeg',
                                                                      'jpg',
                                                                      'png',
                                                                    ]
                                                                    const fileExtension = file.name
                                                                      .split('.')
                                                                      .pop()
                                                                      ?.toLowerCase()
                                                                    if (
                                                                      fileExtension &&
                                                                      !allowedExtensions.includes(
                                                                        fileExtension
                                                                      )
                                                                    ) {
                                                                      if (file) {
                                                                        const allowedExtensions = [
                                                                          'pdf',
                                                                          'jpeg',
                                                                          'jpg',
                                                                          'png',
                                                                        ]
                                                                        const fileExtension = file.name
                                                                          .split('.')
                                                                          .pop()
                                                                          ?.toLowerCase()
                                                                        if (
                                                                          fileExtension &&
                                                                          !allowedExtensions.includes(
                                                                            fileExtension
                                                                          )
                                                                        ) {
                                                                          Swal.fire({
                                                                            title:
                                                                              'error solo archivos .pdf, .jpeg, .jpg, .png son aceptados',
                                                                            icon: 'error',
                                                                            showConfirmButton: false,
                                                                            timer: 1500,
                                                                          })
                                                                          event.target.value = null
                                                                          return
                                                                        }
                                                                      }
                                                                    }
                                                                    const maxFileSizeInBytes =
                                                                      1.5 * 1024 * 1024
                                                                    if (file.size > maxFileSizeInBytes) {
                                                                      Swal.fire({
                                                                        title:
                                                                          'Error: El tamaño del archivo no puede ser mayor a 1.5 MB',
                                                                        icon: 'error',
                                                                        showConfirmButton: false,
                                                                        timer: 1500,
                                                                      })
                                                                      event.target.value = null
                                                                      return
                                                                    }
                                                                  }
                                                                  setDocumentSelected(document.id)
                                                                  setFiles(file)
                                                                }} />
                                                            </>
                                                          )}
                                                          {files === null ? (
                                                            <></>
                                                          ) : (
                                                            <>
                                                              {document.id === documentSelected &&
                                                                <div className='flex flex-col'>
                                                                  <div className='flex gap-1'>
                                                                    {documentDisclaimer === '' ?
                                                                      <div className='flex flex-col items-center'>
                                                                        <button
                                                                          style={{
                                                                            flexDirection: 'column',
                                                                            display: 'flex',
                                                                            width: '58px',
                                                                            height: '40px',
                                                                            padding: '10px 15px',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            borderRadius: '30px',
                                                                            border: '1px solid #FFF',
                                                                            background: '#374151',
                                                                            boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)'
                                                                          }}
                                                                          type='button'
                                                                          onClick={() => {
                                                                            setDocumentId('')
                                                                            setProductId('')
                                                                            handleUpload(documentId, productId, member.memberName, member.memberType)
                                                                          }}>
                                                                          <img style={{ width: '15px', height: '15px' }} src={Upload} alt="Upload" />
                                                                          <a style={{ color: 'white', fontSize: '8px' }}> Subir</a>
                                                                        </button>
                                                                      </div>
                                                                      : (
                                                                        <div className='flex flex-col items-center'>
                                                                          <button
                                                                            style={{
                                                                              flexDirection: 'column',
                                                                              display: 'flex',
                                                                              width: '58px',
                                                                              height: '40px',
                                                                              padding: '10px 15px',
                                                                              justifyContent: 'center',
                                                                              alignItems: 'center',
                                                                              borderRadius: '30px',
                                                                              border: '1px solid #FFF',
                                                                              background: '#374151',
                                                                              boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)'
                                                                            }}
                                                                            type='button'
                                                                            className='uploader-documents-buttons-available'
                                                                            onClick={() => {
                                                                              setDisplayPopup3(true)
                                                                              setActive5(active5 + 1)
                                                                            }}>
                                                                            <img style={{ width: '15px', height: '15px' }} src={Upload} alt="Upload" />
                                                                            <a style={{ color: 'white', fontSize: '8px' }}> Subir</a>
                                                                          </button>
                                                                        </div>)}
                                                                    {displayPopup3 === true ? (
                                                                      <div className='disclaimer-popup'>
                                                                        <div className='uploader-popup'>
                                                                          <img
                                                                            style={{
                                                                              width: '80px',
                                                                              height: '80px',
                                                                              border: '2px solid #bf3c3c',
                                                                              padding: '1rem',
                                                                              borderRadius: '100%',
                                                                              marginBottom: '2rem',
                                                                            }}
                                                                            src='https://img.icons8.com/ios-filled/50/bf3c3c/exclamation-mark.png'
                                                                            alt='exclamation-mark' />
                                                                          <b>{documentDisclaimer}</b>
                                                                          <div className='flex gap-2'>
                                                                            <button
                                                                              style={{
                                                                                top: '0',
                                                                                marginTop: '2rem',
                                                                                width: '5rem',
                                                                              }}
                                                                              type='button'
                                                                              className='uploader-documents-buttons-available '
                                                                              onClick={() => {
                                                                                setDisplayPopup3(false)
                                                                                setDocumentDisclaimer('')
                                                                                setDocumentId('')
                                                                                setProductId('')
                                                                                handleUpload(documentId, productId, member.memberName, member.memberType)
                                                                              }}>
                                                                              Acepto
                                                                            </button>
                                                                            <button
                                                                              style={{
                                                                                top: '0',
                                                                                marginTop: '2rem',
                                                                                width: '5rem',
                                                                              }}
                                                                              className='uploader-documents-buttons-available '
                                                                              type='button'
                                                                              onClick={() => {
                                                                                setFiles(null)
                                                                                setDocumentDisclaimer('')
                                                                                setDisplayPopup3(false)
                                                                                refetch()
                                                                                docRefetch()
                                                                                setTimeout(() => {
                                                                                  refetch()
                                                                                  docRefetch()
                                                                                  setActive4(0)
                                                                                }, 300)
                                                                              }}>
                                                                              Cancelar
                                                                            </button>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    ) : null}
                                                                    <div className='flex flex-col items-center'>
                                                                      <button
                                                                        style={{
                                                                          flexDirection: 'column',
                                                                          display: 'flex',
                                                                          width: '58px',
                                                                          height: '40px',
                                                                          padding: '10px 15px',
                                                                          justifyContent: 'center',
                                                                          alignItems: 'center',
                                                                          borderRadius: '30px',
                                                                          border: '1px solid #FFF',
                                                                          background: '#8899A8',
                                                                          boxShadow: '0px 10px 15px 0px rgba(5, 13, 29, 0.18)'
                                                                        }}
                                                                        type='button'
                                                                        onClick={() => {
                                                                          setFiles(null)
                                                                          setDocumentDisclaimer('')
                                                                          refetch()
                                                                          docRefetch()
                                                                          setTimeout(() => {
                                                                            refetch()
                                                                            docRefetch()
                                                                            setActive4(0)
                                                                          }, 300)
                                                                        }}>
                                                                        <img style={{ width: '15px', height: '15px' }} src={Delete} alt="Delete" />
                                                                        <a style={{ color: 'white', fontSize: '8px' }}> Eliminar</a>
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              } </>)}
                                                        </div>
                                                      </div>
                                                      {document.document.translate === true ? (
                                                        <div className='flex gap-2' style={{ backgroundColor: 'white', width: '12rem', borderRadius: '10px', border: '2px solid  #DFE4EA', background: '#FFF' }}>
                                                          <div style={{ backgroundColor: '#FBBF24', display: 'flex', padding: '12px 7px', flexDirection: 'column', alignItems: 'center', gap: '10px', alignSelf: 'stretch', borderRadius: '8px 0 0 8px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={World} alt="World" />
                                                          </div>
                                                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            <p style={{ fontSize: '16px' }}>Traducir desde</p>
                                                            <button
                                                              onClick={handleTranslationClick}
                                                              className='powerup-card-btn'
                                                            >
                                                              <img src={Car} alt="Car" />
                                                              <p style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>CAD$ 37</p>
                                                            </button>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                      {document.document.legalize === true ? (
                                                        <div className='uploader-popup-container uploader-popup-container-blue'>
                                                          <div style={{ backgroundColor: '#8099EC', display: 'flex', padding: '12px 7px', flexDirection: 'column', alignItems: 'center', gap: '10px', alignSelf: 'stretch', borderRadius: '8px 0 0 8px' }}>
                                                            <img style={{ width: '16px', height: '16px' }} src={Pencil} alt="Pencil" />
                                                          </div>
                                                          <div className='text-manager'>
                                                            <p className='hide-tex' style={{ fontSize: '16px' }}>Legalizar desde</p>
                                                            <p className='hide-tex2' style={{ fontSize: '16px', margin: '0' }}><b>Legalizar</b></p>
                                                            <a
                                                              style={{ width: 'fit-content', paddingLeft: '1rem', paddingRight: '1rem' }}
                                                              target='_blank'
                                                              href='https://www.immilandcanada.com/migracion/affidavit'
                                                              className='powerup-card-btn'
                                                              rel='noreferrer'>
                                                              <img src={Car} alt="Car" />
                                                              <p className='hide-tex' style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>CAD$ 80</p>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </li>
                                              </>
                                            ) : null}
                                          </>
                                        }
                                      </>
                                    ))}
                                  </>
                                )}
                              </>
                            </div>
                          </>
                        ))}
                  </ul>
                </>
              )}
              {(maxStep === 3 &&
                numPending === 0 &&
                numDraft === 0 &&
                numReview >= 0 &&
                numAproved >= 0) ||
                activeNotificationForMail === true ? (
                <div className={fondoGris ? 'fondo-gris' : ''}>
                  <div className='continue-btn-continer'>
                    <div className='continue-btn-main'>
                      <img src={TumbsUp} alt="TumbsUp" />
                      <h1 style={{ color: '#13C296', fontSize: '24px', marginTop: '1.8rem' }}>¡Muy bien!</h1>
                      <h1 style={{ color: '#4F4F4F', fontSize: '24px' }}>¡Ha adjuntado todos sus documentos!</h1>
                      <p style={{ color: '#637381', fontSize: '18px', width: '80%', textAlign: 'center', marginTop: '1.8rem' }}>Hemos notificado a tu consultor(a)  <b>así que ponga a atención a tu proceso.</b></p>
                      <button
                        style={{ marginTop: '2rem', width: '8rem', height: '3rem' }}
                        type='button'
                        className='powerup-card-btn'
                        onClick={() => {
                          setStep(4)
                          GetMainId(contractId)
                          if (maxStep === 3) {
                            setStepBar(100)
                            setMaxStep(5)
                          }
                          setFondoGris(false)
                        }}>
                        <p style={{ color: 'white', fontSize: '18px', margin: '0' }}>Continuar</p>
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (<></>)}

          {tranlationHover === true ? (
            <CSSTransition
              in={showSlideOver}
              timeout={300}
              classNames="overlay"
              unmountOnExit
              onExited={handleCloseSlideOver}>
              <SlideOverComponent onClose={handleCloseSlideOver} />
            </CSSTransition>) : null}
        </div>
      </div>
      </>
      {popUpSumited === true ?
        <div className='toast-style-container'>
          <div className='toast-style'>
            <div
              style={{ backgroundColor: '#22AD5C', padding: '3px', borderRadius: '50%' }}>
              <img src={checkmark_white} alt="checkmark_white" />
            </div>
            Documento enviado con éxito
          </div>
        </div> : null}
      <CompletedContractsModal
        visible={isModalVisible}
        onClose={handleCancel}
        setContractsFound={setContractsFound}
        contractsFound={contractsFound}
        data={dataSelected}
        userEmail={dataSelected?.email || ''} />
    </div>
  )
}
export default UploaderDocuments
