/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState, Dispatch, SetStateAction, useEffect } from 'react'
import { RootObjectCompletedContracts } from 'app/modules/apps/docusign/_models'
import Arrow from '../../icons/arrow-left.svg'
import Check from '../../../dashboard/userDasboardComponents/userDashboardIcons/checkmark-circle.svg'
import Chevron2 from '../../icons/chevron-right2.svg'
import Box from '../../icons/box-4.svg'
import Clock from '../../icons/clock-alt-1.svg'
import ChevronRightIcon from '../../icons/chevron-right.svg'
import Send from '../../../contratationApp/icons/Sendplane.svg'
import { useQuery } from 'react-query'
import { getContractByUser } from 'app/modules/apps/contract/_requests'
import { RootStoreContext } from 'stores/rootStore'
import MyContractStep from '../../userContratationmenu/MyContractStep'
import { updateContracts } from 'app/modules/apps/docusign/_requests'
import PhotoView from 'app/pages/services/services/supportDocumentsClient/PhotoView'
import DocumentView from 'app/pages/services/services/supportDocumentsClient/DocumentView'
import { downloadFileLibrary } from 'app/modules/apps/library/core/_requests'
import Swal from 'sweetalert2'
import {
  getIntakeDocumentsbyContractId,
  updateIntakeDocComments,
  updateIntakeDocStatus
} from 'app/modules/apps/userDocument/_requests'
import { sendEmailReviewOfLettersCompleted } from 'app/modules/apps/users/core/_requests'


interface IProps {
  name: string
  contractData: RootObjectCompletedContracts | undefined
  contractStatus: string
  step: number
  setStep: Dispatch<SetStateAction<number>>
  contractId: string
  setContractId: Dispatch<SetStateAction<string>>
  start: Dispatch<SetStateAction<boolean>>
  maxStep: number
  setMaxStep: Dispatch<SetStateAction<number>>
  stepBar: number
  setStepBar: Dispatch<SetStateAction<number>>
  listProducts: string[]
  setListProducts: Dispatch<SetStateAction<string[]>>
  contract: any
}

const UploaderLetters: React.FC<IProps> = ({
  step,
  setStep,
  contractId,
  maxStep,
  setMaxStep,
  setStepBar,
  listProducts,
  contract,
}) => {
  const rootStore = useContext(RootStoreContext)
  const { user, updatingContracts } = rootStore.authStore
  const [loading, setLoading] = useState(false)
  const [showContract, setShowContract] = useState(false)
  const [showMainContainer, setShowMainContainer] = useState(true)
  const [showContractInfo, setShowContractInfo] = useState<any>()
  const [lookDocument, setLookDocument] = useState<any>()
  const [lookImage, setLookImage] = useState<any>()
  const [docStatus, setDocStatus] = useState('')
  const [comment, setComment] = useState('')
  const [currentId, setcurrentId] = useState('')
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null); // Estado para manejar el documento seleccionado
  const [showDocumentView, setShowDocumentView] = useState<boolean>(false); // Mostrar el visor de documentos
  const {
    data: dataFromDatabase,
    status: statusFromDatabase,
    refetch: refetchFromDataBase,
  } = useQuery('allcontrDbss', async () => await getContractByUser(user?.id!), {
    enabled: user?.id !== undefined,
    cacheTime: 0
  }) /* contratos */

  const {
    data: dataDocumentsbyContractId,
    status: statusDocumentsbyContractI,
    refetch: refetchDocumentsbyContractI,
  } = useQuery('DocumentsbyContractI', async () => await getIntakeDocumentsbyContractId(contractId), {
    retry: false,
    cacheTime: 0,
  })
  console.log("Data documents by contract ID:", dataDocumentsbyContractId);
  console.log("Status of documents by contract ID:", statusDocumentsbyContractI);
  const GetMainId = (contractId: string) => {
    dataFromDatabase.data.map((cont: any) => {
      if (contractId === cont.contracts_users_envelope_id) {
        setSteps(cont.contracts_users_id)
      } else {
        setStatus(cont.contracts_users_id)
      }
    })
  }

  const setSteps = async (mainId: string) => {
    let dataContractData: object
    if (mainId != null) {
      dataContractData = {
        step: step > 3 ? step : 3,
        status: 1,
        list_products: listProducts,
      }
    } else {
      dataContractData = {
        step: step > 3 ? step : 3,
        status: 1,
        list_products: listProducts,
      }
    }
    const result = await updateContracts(mainId, dataContractData)
    if (result) {
      updatingContracts()
    }
  }

  const setStatus = async (mainId: string) => {
    let dataContractData: object
    if (mainId != null) {
      dataContractData = {
        status: 0,
      }
    } else {
      dataContractData = {
        status: 0,
      }
    }
    const result = await updateContracts(mainId, dataContractData)
    if (result) {
      updatingContracts()
    }
  }

  const convertDocument = async (id: string) => {
    const file = await downloadFileLibrary(id, () => { })
    console.log(
      '🚀 ~ file: ListDocumentsDetailsServices.tsx ~ line 33 ~ handleDownload ~ file',
      JSON.stringify(file)
    )
    setLookDocument(file)
  }

  const convertImage = async (document: any) => {
    const file = await downloadFileLibrary(document, () => { })
    console.log(
      '🚀 ~ file: ListDocumentsDetailsServices.tsx ~ line 33 ~ handleDownload ~ file',
      JSON.stringify(file)
    )
    const fileReader = new FileReader()
    fileReader.onload = () => {
      const arrayBuffer = fileReader.result
      if (arrayBuffer instanceof ArrayBuffer) {
        const byteArray = new Uint8Array(arrayBuffer)
        const blob = new Blob([byteArray], { type: 'image/png' })
        const imageUrl = URL.createObjectURL(blob)
        setLookImage(imageUrl)
      } else {
        console.error('Error reading the file.')
      }
    }
    fileReader.onerror = () => {
      console.error('FileReader error:', fileReader.error)
    }
    fileReader.readAsArrayBuffer(file)
  }

  const popupRejectedAlert = () => {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.zIndex = '1';
    document.body.appendChild(overlay)
    Swal.fire({
      title: '<span style="color: #13C296;">Cambios guardados correctamente</span>',
      icon: 'info',
      html:
        '<p style="color: black; margin-bottom: 30px;">Se enviará un mensaje de al case worker para aplicar los cambios correspondientes</p>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',
      },
      buttonsStyling: false,
      backdrop: false,
    }).then((result) => {
      document.body.removeChild(overlay);
    });
    const popup = document.querySelector('.swal2-popup') as HTMLElement | null;
    if (popup) {
      popup.style.zIndex = '10000';
    }
  }

  const popupAproved = () => {
    // Agregar fondo gris transparente a toda la ventana
    const overlay = document.createElement('div');
    overlay.classList.add('overlay'); // Agregar la clase 'overlay'
    document.body.appendChild(overlay);

    Swal.fire({
      title: '<span style="color: #13C296;">Notificacion enviada</span>',
      icon: 'info',
      html:
        '<p style="color: black; margin-bottom: 30px;">Cambios guardados correctamente</p>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',
      },
      buttonsStyling: false,
      backdrop: false,
    }).then((result) => {

      document.body.removeChild(overlay);
    });
  };

  const handleComments = async (id: any, comment: string, authorEmail: string, userEmail: string) => {
    await updateIntakeDocComments(id, comment);


    try {

      await sendEmailReviewOfLettersCompleted(authorEmail, userEmail);
      console.log('Correo enviado correctamente');
    } catch (error) {
      console.error('Error al enviar el correo:', error);
    }
  };


  const handleStatusChange = async (id: any, status: string, authorEmail: string, userEmail: string) => {
    await updateIntakeDocStatus(id, status);


    try {
      await sendEmailReviewOfLettersCompleted(authorEmail, userEmail);
      console.log('Correo enviado correctamente');
    } catch (error) {
      console.error('Error al enviar el correo:', error);
    }
  };


  useEffect(() => {
    if (statusDocumentsbyContractI === 'success') {
      console.log(dataDocumentsbyContractId, 'documentByContract')
    }
  }, [statusDocumentsbyContractI, dataDocumentsbyContractId])


  return (
    <div>
      {showMainContainer ? (
        <div className='main-data-continer'>
          {loading === false ?
            <>
              <div className='text-container'>
                <button
                  className='button-ui button-secondary button-s'
                  onClick={() => { setStep(1) }}>
                  <img src={Arrow} alt="Arrow" />
                  <p>VOLVER A SOLICITUDES</p>
                </button>
                <div style={{ width: '100%', height: '1px', backgroundColor: '#DFE4EA', marginTop: '1rem', marginBottom: '1rem' }}></div>
                <div className='step-three-header-container'>
                  <div>
                    <h1 className='step-header-title mt-4'>Contrato n° {contractId}</h1>
                    {contract.map((item: any, index: number) =>
                      <React.Fragment key={index}>
                        {item.envelope_id === contractId ?
                          <p style={{ fontSize: '12px' }}>Contratado el {item.completedDate.substring(0, 10)}</p>
                          : null}
                      </React.Fragment>)}
                  </div>
                  <div style={{ marginRight: '1rem', marginTop: '30px' }}>
                    {statusFromDatabase === "success" ? (
                      dataFromDatabase.data
                        .filter((item: any) => item.contracts_users_envelope_id === contractId)
                        .map((item: any, index: number) => (
                          <button
                            key={index}
                            // style={{
                            //   color: "#01A9DB",
                            //   display: "flex",
                            //   flexDirection: "row",
                            //   gap: "0.3rem",
                            //   alignItems: "center",
                            //   justifyContent: "center",
                            // }}
                            className='button-ui button-tertiary no-border'
                            onClick={() => {
                              setShowMainContainer(false) // Hide the main container
                              setShowContractInfo(item)
                            }}
                          >
                            <img src={Box} alt="Box" />
                            <p style={{ margin: "0",  }}>Ver detalles del contrato</p>
                          </button>
                        ))
                    ) : (
                      <img
                        src="/media/logos/ezgif.com-resize.gif"
                        className="h-10px w-10px"
                        alt="img"
                      />
                    )}
                  </div>
                </div>
                <div className='onboard-guide-container'>
                  {/* Timeline */}
                  <div className='step-line-cont'>
                    {step > 1 && (
                      <div
                        className='guide-number-done one-btn'
                        onClick={() => {
                          setStep(1)
                        }}>
                        <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>1</b></p>
                        <div className='step-number-checked data-final-step-check'>
                          <img style={{ width: '20px', height: '20px' }} src={Check} alt="Check" />
                        </div>
                        <p className='hide-tex step-text' style={{ color: '#13C296' }}>Servicio contratado</p>
                        <div className='greenpath2' style={{ top: '47px', left: '-8px' }} />
                        <img className='data-final-step-chevron'
                          style={{ width: '20px', height: '20px' }}
                          src={Chevron2} alt="Chevron2" />
                      </div>)}

                    {(maxStep > 2 && step > 2) || (maxStep > 2 && step < 2) ? (
                      <div
                        className='guide-number-done two-btn'
                        onClick={() => {
                          setStep(2)
                        }}>
                        <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>2</b></p>
                        <div className='step-number-checked data-final-step-check'>
                          <img style={{ width: '20px', height: '20px' }} src={Check} alt="Check" />
                        </div>
                        <p className='hide-tex step-text' style={{ color: '#13C296' }}> Completar formularios</p>
                        <div className='greenpath2' style={{ top: '47px', left: '-8px' }} />
                        <img className='data-final-step-chevron'
                          style={{ width: '20px', height: '20px' }}
                          src={Chevron2} alt="Chevron2" />
                      </div>
                    ) : step === 2 && (
                      <>
                        <div className='guide-number two-btn'>
                          <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>2</b></p>
                          <p className='hide-tex step-text' style={{ color: 'black' }}> Completar formularios</p>
                          <div className='blackpath' style={{ top: '47px', left: '-8px' }} />
                          <img className='data-final-step-chevron'
                            style={{ width: '20px', height: '20px' }}
                            src={Chevron2} alt="Chevron2" />
                        </div>
                      </>
                    )}

                    {(maxStep > 3 && step > 3) || (maxStep >= 3 && step < 3) ? (
                      <div
                        className='guide-number-done three-btn'
                        onClick={() => {
                          setStep(3)
                        }}>
                        <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>3</b></p>
                        <p className='hide-tex step-text' style={{ color: '#13C296' }}> Adjuntar documentos</p>
                        <div className='greenpath2' style={{ top: '47px', left: '-8px' }} />
                        <img className='data-final-step-chevron'
                          style={{ width: '20px', height: '20px' }}
                          src={Chevron2} alt="Chevron2" />
                      </div>
                    ) : step === 3 ? (
                      <>
                        <div className='guide-number three-btn'>
                          <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>3</b></p>
                          <p className='hide-tex step-text' style={{ color: 'black' }}> Adjuntar documentos</p>
                          <div className='blackpath' style={{ top: '66px', left: '-8px', height: '5.5rem' }} />
                          <img className='data-final-step-chevron'
                            style={{ width: '20px', height: '20px' }}
                            src={Chevron2} alt="Chevron2" />
                        </div>
                      </>
                    ) : (
                      <div className='guide-number-deactivate three-btn'>
                        <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>3</b></p>
                        <p className='hide-tex step-text' style={{ color: '#d1d5db' }}> Adjuntar documentos</p>
                        <div className='graypath' style={{ top: '47px', left: '-8px' }} />
                        <img className='data-final-step-chevron'
                          style={{ width: '20px', height: '20px' }}
                          src={Chevron2} alt="Chevron2" />
                      </div>
                    )}
                    <div
                      className='guide-number-done four-btn'
                      onClick={() => {
                        setStep(4)
                      }}>
                      <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>4</b></p>
                      <p className='hide-tex step-text' style={{ color: '#13C296' }}>Asignación y segimiento</p>
                      <div className='greenpath2' />
                      <img className='data-final-step-chevron'
                        style={{ width: '20px', height: '20px' }}
                        src={Chevron2} alt="Chevron2" />
                    </div>
                    {step === 4 ? (
                      <div className='guide-number four-btn'>
                        <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>4</b></p>
                        <p className='hide-tex step-text' style={{ color: 'black' }}>Asignación y segimiento</p>
                        <div className='blackpath' style={{ top: '66px', left: '-8px', height: '4rem' }} />
                        <img className='data-final-step-chevron'
                          style={{ width: '20px', height: '20px' }}
                          src={Chevron2} alt="Chevron2" />
                      </div>
                    ) : null}
                    {(maxStep < 5) && (
                      <div className='guide-number-deactivate four-btn'>
                        <p className='white-text' style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>4</b></p>
                        <p className='hide-tex step-text' style={{ color: '#d1d5db' }}>Asignación y segimiento</p>
                        <div className='graypath' />
                        <img className='data-final-step-chevron'
                          style={{ width: '20px', height: '20px' }}
                          src={Chevron2} alt="Chevron2" />
                      </div>)}
                     {/*  <button
        onClick={() => {
          setSelectedDocumentId('tu-document-id'); 
          setShowDocumentView(true);
        }}
      >
        Ver Documento
      </button> */}

     
                    {maxStep >= 5 && step < 5 && (
                      <div
                        className='guide-number-done five-btn'
                        onClick={() => {
                          setStep(5)
                        }}
                      >
                        <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>5</b></p>
                        <p className='hide-tex step-text' style={{ color: '#13C296' }}>Confirmar cartas</p>
                      </div>)}
                    {step === 5 && (
                      <div className='guide-number five-btn'>
                        <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>5</b></p>
                        <p className='hide-tex step-text' style={{ color: 'black' }}>Confirmar cartas</p>
                      </div>)}
                    {maxStep < 5 &&
                      <div className='guide-number-deactivate five-btn'>
                        <p className='white-text' style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px' }}><b>5</b></p>
                        <p className='hide-tex step-text' style={{ color: '#d1d5db' }}>Confirmar cartas</p>
                      </div>}
                  </div>
                  <div className='step-three-main-container '>
                    <h1 className='step-header' >Confirmar cartas</h1>
                    <p className='step-header-subtitle'>
                      Revise las cartas redactada y agregue las observaciones que considere necesarias.
                    </p>
                    {lookDocument && (
                      <div className='user-profile-popup'>
                        <div className='user-profile-popup-continer'>
                          <DocumentView document={lookDocument} setLookDocument={setLookDocument} />
                          {docStatus !== 'Aprovado' &&
                            <div
                              style={{
                                width: '100%',
                                height: 'auto',
                                border: '2px solid #374151',
                                padding: '1rem',
                                borderRadius: '10px',
                                marginBottom: '2rem'
                              }}>
                              <div
                                style={{ display: 'flex', flexWrap: 'wrap', gap: '3rem' }}>
                                <div>
                                  <p>Comentar el documento para una corrección o enmienda</p>
                                  <select
                                    className='option-styles'
                                    onChange={(e) => {
                                      setDocStatus(e.target.value)
                                    }}>
                                    <option value=''>Seleccione</option>
                                    <option value='Rechazado'>Comentar</option>
                                  </select>
                                  {/*     <div className='mt-2'>
                                    {docStatus === 'Rechazado' &&
                                      <div className='step-badge-active-success2'
                                        style={{ width: '6rem', height: '1.5rem' }}>
                                        <p className='step-badge-active-success-p-ones'
                                          style={{ fontSize: '12px', margin: '0' }}>Enviado</p>
                                      </div>}
                                  </div> */}
                                </div>
                                {docStatus === 'Rechazado' &&
                                  <div>
                                    <p>Comentar:</p>
                                    <textarea
                                      title='name'
                                      maxLength={400}
                                      defaultValue={comment}
                                      style={{
                                        padding: '1rem',
                                        width: '19rem',
                                        height: '6rem',
                                        borderRadius: '6px',
                                        border: '1px solid #DFE4EA',
                                        background: '#FFF'
                                      }}
                                      onChange={(e) => {
                                        setComment(e.target.value);
                                      }}>
                                    </textarea>
                                    <p style={{ fontSize: '10px' }}>Max 400 caracteres</p>
                                  </div>}
                              </div>
                            </div>}
                          <div className='flex justify-end'>
                            <div
                              className='powerup-card-btn'
                              style={{ width: '8rem' }}
                              onClick={() => {
                                if (docStatus === 'Rechazado') {
                                  popupRejectedAlert()
                                }


                                const letter = dataDocumentsbyContractId.find((doc: any) => doc.id === currentId);
                                const authorEmail = letter?.author?.email;
                                const userEmail = user?.email;

                                if (authorEmail && userEmail) {
                                  handleComments(currentId, comment, authorEmail, userEmail);
                                  handleStatusChange(currentId, docStatus, authorEmail, userEmail);
                                } else {
                                  console.error('Falta el email del autor o del usuario.');
                                }

                                setTimeout(() => {
                                  refetchDocumentsbyContractI()
                                  setComment('')
                                }, 400)

                                setLookDocument(undefined);
                              }}
                            >
                              <p style={{ color: 'white', fontSize: '1.1rem', margin: '0', cursor: 'pointer' }}>Actualizar</p>
                              <img src={Send} alt='Send' />
                            </div>

                          </div>
                        </div>
                      </div>
                    )}
                    {lookImage && (
                      <div className='user-profile-popup'>
                        <div className='user-profile-popup-continer'>
                          <PhotoView image={lookImage} setLookImage={setLookImage} />
                          {docStatus !== 'Aprovado' &&
                            <div
                              style={{
                                width: '100%',
                                height: 'auto',
                                border: '2px solid #374151',
                                padding: '1rem',
                                borderRadius: '10px',
                                marginBottom: '2rem'
                              }}>
                              <div className='flex justify-start w-ful gap-8'>
                                <div>
                                  <p>Comentar el documento para una corrección o enmienda</p>
                                  <select
                                    className='option-styles'
                                    onChange={(e) => {
                                      setDocStatus(e.target.value)
                                    }}>
                                    <option value=''>Seleccione</option>
                                    <option value='Rechazado'>Comentar</option>
                                  </select>
                                  <div className='mt-2'>
                                    {docStatus === 'Rechazado' &&
                                      <div className='step-badge-active-success2'
                                        style={{ width: '6rem', height: '1.5rem' }}>
                                        <p className='step-badge-active-success-p-ones'
                                          style={{ fontSize: '12px', margin: '0' }}>Enviado</p>
                                      </div>}
                                  </div>
                                </div>
                                {docStatus === 'Rechazado' && <div>
                                  Comentar:
                                  <textarea
                                    title='name'
                                    maxLength={400}
                                    defaultValue={comment}
                                    className='text-styles'
                                    style={{ width: '15rem' }}
                                    onChange={(e) => {
                                      setComment(e.target.value);
                                    }}>
                                  </textarea>
                                </div>}
                              </div>
                            </div>}
                          {docStatus !== 'Aprovado' && (
                            <div className='flex justify-end'>
                              <div
                                className='powerup-card-btn'
                                style={{ width: '8rem' }}
                                onClick={() => {
                                  if (docStatus === 'Rechazado') {
                                    popupRejectedAlert();
                                  } else if (docStatus === 'Aprovado') {
                                    popupAproved();
                                  }
                                  const letter = dataDocumentsbyContractId.find((doc: any) => doc.id === currentId);
                                  const authorEmail = letter?.author?.email;
                                  const userEmail = user?.email;
                                  if (authorEmail && userEmail) {
                                    handleComments(currentId, comment, authorEmail, userEmail);
                                    handleStatusChange(currentId, docStatus, authorEmail, userEmail);
                                  } else {
                                    console.error('Falta el email del autor o del usuario.');
                                  }
                                  setTimeout(() => {
                                    refetchDocumentsbyContractI();
                                    setComment('');
                                  }, 400);
                                  setLookDocument(undefined);
                                }}
                              >
                                <p style={{ color: 'white', fontSize: '1.1rem', margin: '0' }}>Actualizar</p>
                                <img src={Send} alt='Send' />
                              </div>
                            </div>
                          )}

                        </div>
                      </div>
                    )}
                    <div>
                      <div style={{ width: '100%' }}>
                        <div style={{ width: '100%', }}>
                          <div style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem'
                          }}>

                            {/* por consignar  */}
                            {dataDocumentsbyContractId && dataDocumentsbyContractId.length === 0 &&
                              <div className='step-two-header'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  border: '1px solid #DFE4EA',
                                  height: 'auto',
                                  borderRadius: '30px',
                                  padding: '2rem'
                                }}>
                                <p style={{ fontSize: '14px', color: '#4B5563', fontWeight: 'bold', margin: 0 }}>
                                  A la espera de redacción de cartas
                                </p>
                              </div>}

                            {dataDocumentsbyContractId && dataDocumentsbyContractId.map((letter: any) =>
                              <>
                                {/* Rechazado */}
                                {letter.status === 'Rechazado' &&
                                  <div
                                    key={letter.id}
                                    className='step-two-header'
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      border: '1px solid #DFE4EA',
                                      height: 'auto',
                                      borderRadius: '30px',
                                      padding: '1rem 2rem'
                                    }}
                                    onClick={() => {
                                      setcurrentId(letter.id)
                                      setDocStatus(letter.status)
                                      setComment(letter.comments)
                                      letter.file.mimetype === 'application/pdf' ?
                                        convertDocument(letter.file.id as string) :
                                        convertImage(letter.file.id as string)
                                    }}>
                                    <p className='uploader-documents-title'>
                                      {letter.document.name}
                                    </p>
                                    <div>
                                      <p style={{ fontSize: '12px', color: '#4B5563', fontWeight: 'bold', margin: 0 }}>
                                        Fecha de actualización:
                                      </p>
                                      <p style={{ fontSize: '12px', color: '#4B5563', fontWeight: 'bold', margin: 0 }}>
                                        {letter.updatedAt.substring(0, 10)}
                                      </p>
                                    </div>
                                    <div>
                                      <p style={{ fontSize: '14px', fontWeight: 'bold', margin: 0 }}>Comentario:</p>
                                      <p style={{ color: '#BC1C21', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>{letter.comments}</p>
                                    </div>
                                    <div>
                                      <div>
                                        <img
                                          className='show-btn'
                                          src={ChevronRightIcon}
                                          alt='ChevronRight' />
                                      </div>
                                    </div>
                                  </div>}

                                {/* por corregir */}

                                {letter.status === 'Pendiente por revisión' &&
                                  <div
                                    key={letter.id}
                                    className='step-two-header'
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      border: '1px solid #DFE4EA',
                                      height: 'auto',
                                      borderRadius: '30px',
                                      padding: '2rem'
                                    }}
                                    onClick={() => {
                                      setcurrentId(letter.id)
                                      setDocStatus(letter.status)
                                      letter.file.mimetype === 'application/pdf' ?
                                        convertDocument(letter.file.id as string) :
                                        convertImage(letter.file.id as string)
                                    }}>
                                    <p className='uploader-documents-title'>
                                      {letter.document.name}
                                    </p>
                                    <div>
                                      <p style={{ fontSize: '12px', color: '#4B5563', fontWeight: 'bold', margin: 0 }}>
                                        Fecha de actualizacion
                                      </p>
                                      <p style={{ fontSize: '12px', color: '#4B5563', fontWeight: 'bold', margin: 0 }}>
                                        {letter.updatedAt.substring(0, 10)}
                                      </p>
                                    </div>
                                    <div>
                                      <div className='step-badge-active-draft2' style={{ width: 'auto', height: '1.5rem', border: '1px solid #8899A8' }}>
                                        <img style={{ width: '16px', height: '16px' }} src={Clock} alt="Clock" />
                                        <p className='step-badge-active-draft-p-ones hide-tex' style={{ fontSize: '12px', margin: '0' }}>Por corregir</p>
                                        <p className='step-badge-active-draft-p-ones hide-tex2' style={{ fontSize: '12px' }}>Por corregir</p>
                                      </div>
                                    </div>
                                    <div>
                                      <div>
                                        <img
                                          className='show-btn'
                                          src={ChevronRightIcon}
                                          alt='ChevronRight'
                                        />
                                      </div>
                                    </div>
                                  </div>}
                              </>)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  </> : <>Cargando...</>
          }
        </div >) : (
        <MyContractStep
          setShowContract={setShowContract}
          setShowContractInfo={setShowContractInfo}
          setStep={setStep}
          setContractId={contractId}
          GetMainId={GetMainId}
          maxStep={maxStep}
          setStepBar={setStepBar}
          setMaxStep={setMaxStep}
          showContractInfo={showContractInfo}
          setShowMainContainer={setShowMainContainer}
        />
      )}
    </div >
  )
}
export default UploaderLetters
