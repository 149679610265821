/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useQuery } from 'react-query'
import { getAllStaff } from 'app/modules/apps/staff/core/_requests'
import { updateContracts } from 'app/modules/apps/docusign/_requests'
import { getContractByUser } from 'app/modules/apps/contract/_requests'
import { RootStoreContext } from 'stores/rootStore'
import './styles.scss'
import {
  sendEmailForAssingnedCaseWorker,
  sendEmailForUserNotifyCaseWorkerAssingned,

} from 'app/modules/apps/users/core/_requests'
import Swal from 'sweetalert2'
import { sendNotification } from 'app/modules/apps/appointments/core/_requests'
import Logo from '../../../contratationApp/icons/logo.png'

interface IProps {
  email: string
  id: string
  contractsFound: any
}

const IntakeList: React.FC<IProps> = ({ email, id, contractsFound }) => {
  const rootStore = useContext(RootStoreContext)
  const [staffId, setStaffId] = useState('')
  const [contractMainId, setContractMainId] = useState('')
  const [isHovered, setIsHovered] = useState(false)
  const [validate, setValidate] = useState(0)
  const [validate1, setValidate1] = useState(false)
  const [department, setDepartment] = useState('')
  const [staff, setStaffAsing] = useState<any>(null)
  const { user } = rootStore.authStore
  const { updatingContracts } = rootStore.authStore
  const { data: StaffData, status: Staffstatus, refetch: StaffRefetch } = useQuery('getStf', async () => await getAllStaff())
  const { data: dataFromDatabase, status: statusFromDatabase } = useQuery('allcontrDb', async () => await getContractByUser(id!)) /* contratos */
  const [active2, setActive2] = useState(false)
  const [photo, setPhoto] = useState('')

  const setStaff = async (staffId: any, contractId: any) => {
    const selectedConsultor = StaffData.find((staff: any) => staff.id === staffId)
    let dataContractData: object
    if (staffId === '') {
      // No hacer nada si staffId está vacío
      return
    }
    if (selectedConsultor) {
      const consultorEmail = selectedConsultor.user.email
      await SendMail(consultorEmail)
    }
    if (contractId != null) {
      dataContractData = {
        staffId: staffId,
      }
    } else {
      dataContractData = {
        staffId: staffId,
      }
    }
    const result = await updateContracts(contractMainId, dataContractData)
    if (result) {
      updatingContracts()
    }
  }

  const setDepartmentForClient = async (department: any, contractId: any) => {
    if (department === '') {
      // No hacer nada si department está vacío
      return
    }
    let dataContractData: object
    if (contractId != null) {
      dataContractData = {
        department: department,
      }
    } else {
      dataContractData = {
        department: department,
      }
    }
    const result = await updateContracts(contractMainId, dataContractData)
    if (result) {
      updatingContracts()
    }
  }

  const SendMailForClient = async () => {
    await sendEmailForUserNotifyCaseWorkerAssingned(email)
  }

  const SendMail = async (consultorEmail: string) => {
    console.log('SendMail')
    await sendEmailForAssingnedCaseWorker(consultorEmail, email)
  }

  const SendMailDepartment = async (selectedDepartment: string) => {
    console.log('SendMailDepartment')
    let departmentEmail = ''
    switch (selectedDepartment) {
      case 'Division de visitantes':
        departmentEmail = 'visitor-visas@immilandcanada.com'
        break
      case 'Division de estudios':
        departmentEmail = 'student-visas@immilandcanada.com'
        break
      case 'Division de trabajo':
        departmentEmail = 'work-visas@immilandcanada.com'
        break
      default:
        console.error('Departamento no válido')
        return
    }
    await sendEmailForAssingnedCaseWorker(departmentEmail, email)
    setDepartmentForClient(selectedDepartment, contractsFound)
  }

  const IwantContract = () => {
    Swal.fire({
      title: '<span style="color: #13C296"><u>¡Excelente!</u></span>',
      icon: 'success',
      html:
        `<p><b>Se ha asignado el Case Worker con éxito!</b></p><br>
       <p>${department}</p><br>
     `,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '¡Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
      },
      buttonsStyling: false,
    })
  }

  const handleAssignClick = async () => {
    StaffRefetch()
    setActive2(false)
    SendMailForClient()
    if (contractsFound !== '' && staffId !== '') {
      console.log('bOTON1')
      await setStaff(staffId, contractsFound)
    }
    if (contractsFound !== '' && department !== '') {
      console.log('bOTON2')
      await SendMailDepartment(department)
    }
    if (user?.id) {
      await sendNotification(user.id, contractsFound) // Enviar notificación
    }
    setValidate1(true)
    setTimeout(() => {
      setValidate1(false)
    }, 2000)
    IwantContract()
  }

  useEffect(() => {
    if (statusFromDatabase && dataFromDatabase) {
      dataFromDatabase.data.map((cont: any) => {
        if (cont.contracts_users_envelope_id === contractsFound) {
          setContractMainId(cont.contracts_users_id)
        }
      })
    }
  }, [statusFromDatabase, dataFromDatabase, contractsFound])

  if (statusFromDatabase === 'success' && Staffstatus === 'success' && active2 === false) {
    dataFromDatabase.data.map((usercontract: any) => {
      if (usercontract.contracts_users_envelope_id === contractsFound) {
        StaffData.map((staff: any) => {
          if (staff.id === usercontract.contracts_users_staffId) {
            if (staff.user.photo !== null) {
              setPhoto(`${staff.user.photo.path}/${staff.user.photo.id}-avatar-large.jpg`)
            }
            setStaffAsing(staff)
          }
        })
      }
    })
    setActive2(true)
  }

  return (
    <div className='manager-carta' >
      <div className='loader-carta'>
        <strong className='caseWorker-title'>Asignar case worker al contrato</strong>
        <p className='text-lg'
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            marginLeft: '1rem',
            fontWeight: 'bold'
          }}>
          <p>Contrato n° <b>{contractsFound}</b></p>
        </p>
        <div className='inputs-container-loader'>
          <div style={{ height: '5rem', marginLeft: '1rem', width: '300px' }}>
            <p>
              Seleccionar un departamento
            </p>
            <select
              name='department'
              id='department'
              className='option-styles-intake'
              onChange={(e: any) => {
                setDepartment(e.target.value)
              }}>
              <option value=''>Seleccione</option>
              <option value='Division de visitantes'>División de visitantes</option>
              <option value='Division de estudios'>División de estudios</option>
              <option value='Division de trabajo'>División de trabajo</option>
            </select>
            {validate > 0 && department === '' && (
              <p style={{ color: '#bf3c3c' }}>Debe seleccionar un departamento</p>
            )}
          </div>
          <div style={{ flex: '1' }} className='flex gap-8 '>
            <div style={{ height: '5rem', marginLeft: '1rem', width: '300px' }}>
              <p>
                Seleccionar un case worker
              </p>
              <select
                name="intake"
                id="intake"
                className="option-styles-intake"
                onChange={(e: any) => {
                  setStaffId(e.target.value);
                }}>
                <option value="">Seleccione</option>
                {StaffData &&
                  StaffData.map((option: any) => {
                    if (option.status.name === 'Active' &&
                      (option.type === 'coo' ||
                        option.type === 'consultant' ||
                        option.type === 'caseworker' ||
                        option.type === 'intake' ||
                        option.type === 'intake_admin' ||
                        option.type === 'consultant_admin' ||
                        option.type === 'caseworker_admin')) {
                      return (
                        <option key={option.id} value={option.id}>
                          {option.jotformName}
                        </option>
                      );
                    }
                    return null; // Si no coincide, no retorna nada
                  })}
              </select>
              {validate > 0 && staffId === '' && (
                <p style={{ color: '#bf3c3c' }}>Debe seleccionar un case worker</p>
              )}
            </div>
          </div>
          <div style={{ flex: '1' }} className='flex gap-8 '>
            <div style={{ height: '5rem', marginLeft: '1rem', width: '300px' }}>
              <p>
                Seleccionar un consultor
              </p>
              <select
                name="intake"
                id="intake"
                className="option-styles-intake"
                onChange={(e: any) => {
                  setStaffId(e.target.value);
                }}>
                <option value="">Seleccione</option>
                {StaffData &&
                  StaffData.map((option: any) => {
                    if (option.status.name === 'Active' &&
                      (option.type === 'coo' ||
                        option.type === 'consultant' ||
                        option.type === 'consultant_admin'
                      )) {
                      return (
                        <option key={option.id} value={option.id}>
                          {option.jotformName}
                        </option>
                      );
                    }
                    return null; // Si no coincide, no retorna nada
                  })}
              </select>
              {validate > 0 && staffId === '' && (
                <p style={{ color: '#bf3c3c' }}>Debe seleccionar un case worker</p>
              )}
            </div>
          </div>
          <button
            className='button-ui button-primary '
            style={{ marginTop: '30px' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleAssignClick}>
            <b >Asignar</b>
            <img
              alt="Calendar"
              className='meet-image2'
              src={isHovered ? '/media/icons/app/menu/arrow-right-white.svg' : '/media/icons/app/menu/arrow-right-white.svg'} />
          </button>
        </div>
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        marginLeft: '1rem',
        marginTop: '1rem'
      }}>
        <div className='consultant-card-container' style={{ border: 'none' }}>
          {active2 === true && staff !== null ? (
            <div className='consultant-card-container-inner'>
              <div className='flex flex-row justify-between w-full' >
                <div className='flex flex-row gap-2 '>
                  <img
                    className='consultant-card-img'
                    src={staff.user.photo !== null ? photo : Logo}
                    alt='staff' />
                  <div className='flex flex-col'>
                    <p className='consultant-card-name' style={{ marginBottom: '0px', fontWeight: 'normal', fontSize: '14px' }}>Consultor Asignado:</p>
                    <div className='flex'>
                      <p className='consultant-card-name'>{staff.user.firstName}</p> &#160;
                      <p className='consultant-card-name'>{staff.user.lastName}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (null)}
        </div>
      </div>
    </div>)
}

export default observer(IntakeList)
